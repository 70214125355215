import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import {
  addHouseHold,
  deleteHouseHold,
  fetchHouseHoldByCriteria,
  fetchHouseHoldByPropertyId,
  fetchHouseHolds,
  updateHouseHold,
} from "../api/houseHoldApi";

export function useHouseholds(rows, page, propertyId) {
  return useQuery({
    queryKey: ["households", { rows, page, propertyId }],
    queryFn: () => fetchHouseHolds(rows, page, propertyId),
    retry: false,
    //staleTime: 10,
    refetchInterval: 400000,

    onSuccess: (data) => {
      return data;
    },
    throwOnError: (error) => {
      console.log("ALL BAD");
      //return error
      //console.error(error.message)
    },
  });
}

export function useHouseHoldByCriteria(data, propertyId) {
  return useQuery({
    queryKey: ["households", { propertyId }],
    queryFn: () => fetchHouseHoldByCriteria(data, propertyId),
    retry: false,
    enabled: data.length >= 3,
    //staleTime: 60 * 1000, //60 seconds
    //enabled: false,
    onSuccess: () => {
      console.log("ALL GOOD");
    },
  });
}

export function useHouseHoldByPropertyId(propertyId) {
  return useQuery({
    queryKey: ["households", { propertyId }],
    queryFn: () => fetchHouseHoldByPropertyId(propertyId),
    retry: false,
    //staleTime: 60 * 10000, //600 seconds
    //enabled: permits_by === 1,
    //enabled: false,
    staleTime: 60000,
    onSuccess: () => {
      console.log("ALL GOOD");
    },
  });
}

export const useInsertHousehold = () => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: addHouseHold,
    networkMode: "offlineFirst",
    retry: false,
    throwOnError: false,
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: ["households"],
        //exact: true,
        refetchActive: true,
        refetchInactive: false,
      });
    },
    onError: (error) => {
      console.log(error);
    },
  });
};

export function useUpdateHousehold() {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: updateHouseHold,
    //networkMode: offlineFirst,
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: ["households"],
        //exact: true,
        refetchActive: true,
        refetchInactive: false,
      });
    },
    onError: () => {
      console.log("error occured");
    },
  });
}

export function useDeleteHousehold() {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: deleteHouseHold,
    onSuccess() {
      queryClient.invalidateQueries({
        queryKey: ["households"],
        //exact: true,
        refetchActive: true,
        refetchInactive: false,
      });
    },
  });
}
