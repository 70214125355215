import { useState } from "react";
import { Button } from "primereact/button";
import { useQueryClient } from "@tanstack/react-query";

import Datatable from "./datatable";
import { useSectionPermissions } from "../../hooks/useSectionPermissions";
import HouseholdSheet from "./household-sheet";
import { Card } from "primereact/card";

const MODAL_KEYS = {
  SHEET: "sheet",
  MODAL: "modal",
};

export default function Household() {
  const [modalKeyOpen, setModalKeyOpen] = useState(null);
  const [householdId, setHouseholdId] = useState(null);
  const queryClient = useQueryClient();

  const permissionsObject = useSectionPermissions("household", [
    "create",
    "show",
    "update",
    "delete",
  ]);

  const handleRefresh = () => {
    queryClient.invalidateQueries({
      queryKey: ["households"],
      refetchActive: true,
      refetchInactive: false,
    });
  };

  const handleOpenSheet = (householdId) => {
    setModalKeyOpen(MODAL_KEYS.SHEET);
    if (householdId) setHouseholdId(householdId);
  };

  const handleShowDetail = (householdId) => {
    setModalKeyOpen(MODAL_KEYS.MODAL);
    if (householdId) setHouseholdId(householdId);
  };

  const handleCloseModal = () => {
    setModalKeyOpen(null);
    setHouseholdId(null);
  };

  return (
    <>
      <Card>
        <div className="flex flex-wrap justify-content-between gap-2 mb-1">
          <div className=" p-toolbar-group-start p-toolbar-group-left">
            <div className="flex align-items-center gap-2">
              <h2 className="font-semibold m-0">Households</h2>
              <Button
                icon="pi pi-refresh"
                rounded
                text
                aria-label="Refresh"
                tooltip="Refresh"
                onClick={handleRefresh}
              />
            </div>
          </div>
          {permissionsObject.create ? (
            <Button
              label="New Household"
              id="newBtn"
              onClick={() => handleOpenSheet()}
            />
          ) : null}
        </div>
        <Datatable
          permissionsObject={permissionsObject}
          onShowDetail={handleShowDetail}
          onEdit={handleOpenSheet}
        />
      </Card>

      {modalKeyOpen === MODAL_KEYS.SHEET ? (
        <HouseholdSheet
          isVisible
          householdId={householdId}
          onClose={handleCloseModal}
        />
      ) : null}
      {/* {modalKeyOpen === MODAL_KEYS.MODAL ? (
        <DarInfoModal isVisible darId={darId} onClose={handleCloseModal} />
      ) : null}
       */}
    </>
  );
}
