import { useEffect, useRef, useState } from "react";
import { usePosition } from "../../context/position";

import { useForm } from "react-hook-form";
import { Controller } from "react-hook-form";

import { useLocalState } from "../../hooks/useLocalState";

import { Sidebar } from "primereact/sidebar";
import { FileUpload } from "primereact/fileupload";
import { InputTextarea } from "primereact/inputtextarea";
import { Button } from "primereact/button";
import { Calendar } from "primereact/calendar";
import { InputSwitch } from "primereact/inputswitch";
import { Dropdown } from "primereact/dropdown";
import { classNames } from "primereact/utils";
import { Image } from "primereact/image";
import { Message } from "primereact/message";
import { readAndCompressImage } from "browser-image-resizer";
import { toast } from "react-toastify";
import { useProperty } from "../../context/PropertyProvider";
import { TOAST_CONFIG } from "../../utils/Constansts";
import { v4 as uuidv4 } from "uuid";
import { useDar, useInsertDar, useUpdateDar } from "../../hooks/darQueries";
import Loader from "../loader";
import { readFiles } from "../../utils";

const MAX_FILES = 13;

const minDate = (userData) => {
  if (userData.isAdmin || userData.isSupervisor) {
    return new Date("2022-01-01");
  } else {
    return new Date();
  }
};

const config = {
  quality: 1,
  maxWidth: 1920,
  maxHeight: 1080,
};

function DarSheet({ isVisible, darId, onClose }) {
  const today = useRef(new Date());
  const [userData] = useLocalState("", "userData");

  const [property] = useProperty();

  const [dailyActivityReportTypes] = useLocalState(
    "",
    "dailyActivityReportTypes"
  );
  const [dailyActivityReportCodes] = useLocalState(
    "",
    "dailyActivityReportCodes"
  );

  const { position, getPosition } = usePosition();

  const [imagesUploaded, setImagesUploaded] = useState([]);
  const [images, setImages] = useState([]);
  const [deletedImages, setDeletedImages] = useState([]);

  const {
    isPending: _isPending,
    isSuccess,
    isError,
    data: fetchedDar,
  } = useDar(darId);
  const dar = !_isPending && darId ? fetchedDar.data.data : null;
  const isPending = darId && _isPending;

  const {
    mutateAsync: mutateInsertDar,
    data: addedDar,
    error: errorInsertDar,
    isError: isErrorInsertDar,
    isSuccess: isSuccessInsertDar,
  } = useInsertDar();

  const {
    mutateAsync: mutateUpdateDar,
    data: updatedDar,
    isSuccess: isSuccessUpdateDar,
  } = useUpdateDar();

  const {
    handleSubmit,
    control,
    formState: { errors, isSubmitting },
  } = useForm({
    values: {
      dailyActivityReportTypeId:
        dar?.daily_activity_report_type_id ??
        dailyActivityReportTypes[0].daily_activity_report_type_id,
      dailyActivityReportCodeId:
        dar?.daily_activity_report_code_id ??
        dailyActivityReportCodes[0].daily_activity_report_code_id,
      details: dar?.details ?? "",
      dar_date: dar?.dar_date ? new Date(dar.dar_date) : today.current,
      send_notification: Boolean(dar?.send_notification ?? 0),
      include_gps_coordinates: Boolean(dar?.include_gps_coordinates ?? 0),
    },
  });

  useEffect(() => {
    // refresh position
    getPosition();
  }, []);

  useEffect(() => {
    if (isSuccessInsertDar || isSuccessUpdateDar) {
      onClose();

      if (darId === null) {
        toast.success(
          `You have created DAR ID: ${addedDar?.data.data.daily_activity_report_id}`,
          TOAST_CONFIG
        );
      } else {
        toast.success(
          `You have updaated DAR ID: ${updatedDar?.data.data.daily_activity_report_id}`,
          TOAST_CONFIG
        );
      }
    }
  }, [addedDar, updatedDar]);

  useEffect(() => {
    if (dar) {
      setImagesUploaded(dar.images);
    }
  }, [dar]);

  useEffect(() => {
    if (isErrorInsertDar) {
      console.log(isErrorInsertDar);
      console.log(errorInsertDar);
    }
  }, [isErrorInsertDar]);

  const onSubmit = async (data) => {
    try {
      const { latitude, longitude } = position;

      let base64Images = [];
      let _images = images;
      let imagesDel = [];

      if (dar?.images?.length) {
        _images = images.filter((f) => !dar.images.includes(f));

        imagesDel = dar.images.filter((f) => !images.find((_f) => _f === f));
      }

      if (_images.length) {
        const resized = [];
        for (let img of _images) {
          const res = await readAndCompressImage(img, config);
          resized.push(res);
        }
        base64Images = await readFiles(resized);

        base64Images = base64Images.map((b) => b.base64);
      }

      const darData = {
        daily_activity_report_type_id: data.dailyActivityReportTypeId,
        daily_activity_report_code_id: data.dailyActivityReportCodeId,
        dar_date: new Date(data.dar_date).toISOString(),
        automatic_dar: 0,
        details: data.details,
        gps_coordinates: `${latitude},${longitude}`,
        include_gps_coordinates: data.include_gps_coordinates,
        map: null,
        property_id: property,
        send_notification: data.send_notification,
        images: base64Images,
        images_del: imagesDel,
      };

      if (darId === null) {
        await mutateInsertDar({ json: darData, persistOnStorage: true });
      } else {
        darData["daily_activity_report_id"] = darId;
        await mutateUpdateDar(darData);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const handleUploadFiles = async (e) => {
    if (e.files.length >= MAX_FILES) return;
    const uploadedFiles = e.files;
    setImages(uploadedFiles);
  };

  const removeFiles = async (e, file) => {
    e.preventDefault();
    if (file.includes("blob")) {
      setImages((imgs) => {
        const filtered = imgs.filter((i) => i.objectURL !== file.objectURL);
        return filtered;
      });
    } else {
      setDeletedImages([...deletedImages, file]);
    }
  };

  return (
    <>
      <Sidebar
        visible={isVisible}
        position="right"
        onHide={onClose}
        className="w-full md:w-6 lg:w-5"
      >
        {isPending ? (
          <Loader />
        ) : (
          <>
            <h2>{darId ? "Update DAR" : "New DAR"}</h2>
            {!darId ? (
              <Message
                severity="info"
                text="Remember to explain WHO, WHAT, WHEN, WHERE, WHY, HOW and CONCLUSION"
                className="font-bold mb-3"
              />
            ) : null}
            <form className="p-fluid" onSubmit={handleSubmit(onSubmit)}>
              <div className="field">
                <div className="formgrid grid">
                  <div className="field col">
                    <span className="p-float-label">
                      <Controller
                        control={control}
                        name="dailyActivityReportTypeId"
                        rules={{
                          validate: (value) =>
                            value !== undefined || "You must select a DAR type",
                          valueAsNumber: true,
                        }}
                        render={({ field }) => (
                          <Dropdown
                            id={field.name}
                            filter
                            placeholder="Select a type"
                            optionLabel="type"
                            optionValue="daily_activity_report_type_id"
                            options={dailyActivityReportTypes}
                            {...field}
                          />
                        )}
                      />
                      <label htmlFor="type">Type</label>
                    </span>
                    <small className="block pt-1">
                      {errors.dailyActivityReportTypeId && (
                        <div className="text-red-500">
                          {errors.dailyActivityReportTypeId.message}
                        </div>
                      )}
                    </small>
                  </div>
                  <div className="field col">
                    <span className="p-float-label">
                      <Controller
                        control={control}
                        name="dailyActivityReportCodeId"
                        rules={{
                          validate: (value) =>
                            value !== undefined || "You must select a DAR code",
                          valueAsNumber: true,
                        }}
                        render={({ field }) => (
                          <Dropdown
                            id={field.name}
                            filter
                            placeholder="Select a code"
                            optionLabel="type"
                            optionValue="daily_activity_report_code_id"
                            options={dailyActivityReportCodes}
                            {...field}
                          />
                        )}
                      />
                      <label htmlFor="type">Code</label>
                    </span>
                    <small className="block pt-1">
                      {errors.dailyActivityReportCodeId && (
                        <div className="text-red-500">
                          {errors.dailyActivityReportCodeId.message}
                        </div>
                      )}
                    </small>
                  </div>
                </div>
              </div>

              <div className="field">
                <span className="p-float-label">
                  <Controller
                    control={control}
                    name="dar_date"
                    render={({ field }) => (
                      <Calendar
                        id="dar_date"
                        name="dar_date"
                        dateFormat="yy-mm-dd"
                        showButtonBar
                        showTime
                        hourFormat="24"
                        maxDate={new Date()}
                        minDate={minDate(userData[0])}
                        hideOnDateTimeSelect
                        value={field.value}
                        onChange={(e) => field.onChange(e.value)}
                      />
                    )}
                  />

                  <label htmlFor="dar_date">
                    Date <small>(yyyy-mm-dd)</small>
                  </label>
                </span>
                <small className="block pt-1">
                  {errors.dar_date && (
                    <div className="text-red-500">
                      {errors.dar_date.message}
                    </div>
                  )}
                </small>
              </div>

              <div className="field">
                <span className="p-float-label">
                  <Controller
                    name="details"
                    control={control}
                    rules={{ required: "Details is required." }}
                    render={({ field, fieldState }) => (
                      <InputTextarea
                        id={field.name}
                        {...field}
                        value={field.value ?? ""}
                        autoFocus
                        maxLength={500}
                        rows={5}
                        cols={30}
                        className={classNames({
                          "p-invalid": fieldState.invalid,
                        })}
                      />
                    )}
                  />

                  <label htmlFor="details">
                    Details <small>(max 500 characters)</small>
                  </label>
                </span>
                <small className="block pt-1">
                  {errors.details && (
                    <div className="text-red-500">{errors.details.message}</div>
                  )}
                </small>
              </div>

              <div className="field">
                <Controller
                  control={control}
                  name={"images"}
                  // rules={{ required: "Image is required" }}
                  render={({ field: { value, onChange, ...field } }) => {
                    return (
                      <FileUpload
                        multiple
                        mode="advanced"
                        {...field}
                        value={value?.fileName}
                        onSelect={handleUploadFiles}
                        type="file"
                        id="images"
                        chooseLabel="Select Image/s"
                        uploadOptions={{ className: "hidden" }}
                      />
                    );
                  }}
                />

                <small className="block pt-1">
                  {errors.images && (
                    <div className="text-red-500">{errors.images.message}</div>
                  )}
                </small>
              </div>
              {imagesUploaded ? (
                imagesUploaded.length > 0 ? (
                  <div className="field">
                    <ul className="m-0 p-0 list-none border-1 surface-border border-round p-2 flex flex-column gap-1 w-full ">
                      {imagesUploaded.map((url, i) => (
                        <li
                          key={uuidv4()}
                          className={`hover:surface-hover border-round border-1 border-transparent transition-all transition-duration-200 flex align-items-center justify-content-between border-primary'}`}
                          //onContextMenu={(event) => onRightClick(event, user)}
                        >
                          <div className="flex align-items-center gap-1">
                            <Image
                              src={url}
                              zoomSrc={imagesUploaded[i]}
                              key={i}
                              width="80"
                              height="80"
                              preview
                            />
                            <Button
                              icon="pi pi-trash"
                              onClick={(e) => removeFiles(e, url)}
                              severity="danger"
                              size="small"
                              style={{ backgroundColor: "#d32f2f80" }}
                            />
                          </div>
                        </li>
                      ))}
                    </ul>
                  </div>
                ) : null
              ) : null}
              <div className="field">
                <span className="p-float-label">
                  <Controller
                    control={control}
                    name="send_notification"
                    render={({
                      field: { onChange, onBlur, value, name, ref },
                      fieldState: { invalid, isTouched, isDirty, error },
                      formState,
                    }) => (
                      <InputSwitch
                        onBlur={onBlur} // notify when input is touched
                        onChange={onChange} // send value to hook form
                        checked={value}
                        inputRef={ref}
                      />
                    )}
                  />

                  <label htmlFor="send_notification" className="ml-2">
                    &nbsp; &nbsp; &nbsp; &nbsp; Send notification to prop.
                    manager
                  </label>
                </span>
              </div>

              <div className="field">
                <span className="p-float-label">
                  <Controller
                    control={control}
                    name="include_gps_coordinates"
                    render={({
                      field: { onChange, onBlur, value, name, ref },
                      fieldState: { invalid, isTouched, isDirty, error },
                      formState,
                    }) => (
                      <InputSwitch
                        onBlur={onBlur} // notify when input is touched
                        onChange={onChange} // send value to hook form
                        checked={value}
                        inputRef={ref}
                        //disabled={userData[0].isOfficer || userData[0].isPostCommander || userData[0].isSupervisor ? true : false}
                      />
                    )}
                  />

                  <label htmlFor="include_gps_coordinates" className="ml-2">
                    &nbsp; &nbsp; &nbsp; &nbsp; Include GPS coordinates
                  </label>
                </span>
              </div>

              <Button
                disabled={isSubmitting}
                type="submit"
                label={isSubmitting ? "Loading..." : "Submit"}
                className="mt-2"
              />
              {errors.root && (
                <div className="text-red-500">{errors.root.message}</div>
              )}
            </form>
          </>
        )}
      </Sidebar>
    </>
  );
}

export default DarSheet;
