import { Sidebar } from "primereact/sidebar";
import Loader from "../loader";
import { useForm } from "react-hook-form";
import { InputText } from "primereact/inputtext";
import { Button } from "primereact/button";
import {
  useInsertHousehold,
  useUpdateHousehold,
} from "../../hooks/houseHoldQueries";
import { useUpdateEffect } from "primereact/hooks";
import { toast } from "react-toastify";
import { TOAST_CONFIG } from "../../utils/Constansts";
import { useProperty } from "../../context/PropertyProvider";

function Header({ householdId, isSubmitting }) {
  const action = isSubmitting ? "Updat" : "Creat";

  return (
    <div className="w-full flex align-items-center justify-content-between mb-4">
      <div>
        <h2>{householdId ? "Update household" : "Create household"}</h2>
      </div>
      <div className="flex gap-2">
        <Button
          disabled={isSubmitting}
          type="submit"
          form="household"
          label={`${action}${isSubmitting ? "ing..." : "e"}`}
        />
      </div>
    </div>
  );
}

export default function HouseholdSheet({ isVisible, householdId, onClose }) {
  const [property] = useProperty();
  const {
    register,
    handleSubmit: formSubmit,
    formState: { errors, isSubmitting },
  } = useForm({
    name: "",
    street: "",
    number: "",
  });

  const isPending = false;

  const {
    mutateAsync: mutateInsert,
    data: dataAdded,
    error: errorInset,
    isError: isErrorInsert,
    isSuccess: isSuccessInsert,
  } = useInsertHousehold();

  const {
    mutateAsync: mutateUpdate,
    data: dataUpdated,
    isError: isErrorUpdate,
    isSuccess: isSuccessUpdate,
  } = useUpdateHousehold();

  useUpdateEffect(() => {
    if (isSuccessInsert || isSuccessUpdate) {
      onClose();

      const action = householdId ? "updated" : "created";
      const {
        data: {
          data: { daily_activity_report_id },
        },
      } = householdId ? dataUpdated : dataAdded;

      toast.success(
        `You have ${action} DAR ID: ${daily_activity_report_id}`,
        TOAST_CONFIG
      );
    }
  }, [dataUpdated, dataAdded]);

  useUpdateEffect(() => {
    if (isErrorInsert || isErrorUpdate) {
      const msg = `Something went wrong. Please try again or contact with an administrator.`;
      toast.error(msg, TOAST_CONFIG);
    }
  }, [isErrorInsert, isErrorUpdate]);

  const handleSubmit = async (data) => {
    const mutate = householdId ? mutateUpdate : mutateInsert;
    let household = { ...data, property_id: property };

    if (householdId) {
      household.house_hold_id = householdId;
    } else {
      household = {
        json: household,
        persistOnStorage: true,
      };
    }

    await mutate(household);
  };

  return (
    <Sidebar
      visible={isVisible}
      position="right"
      header={() => (
        <Header householdId={householdId} isSubmitting={isSubmitting} />
      )}
      onHide={onClose}
      className="w-full md:w-7 lg:w-7"
    >
      {isPending ? (
        <Loader />
      ) : (
        <form
          id="household"
          className="p-fluid pt-2"
          onSubmit={formSubmit(handleSubmit)}
        >
          <div className="field">
            <span className="p-float-label">
              <InputText
                autoFocus
                maxLength={50}
                {...register("name", { required: "This field is required" })}
              />

              <label htmlFor="name">Name</label>
            </span>
            <small className="block pt-1">
              {errors.name && (
                <div className="text-red-500">{errors.name.message}</div>
              )}
            </small>
          </div>

          <div className="formgrid grid">
            <div className="field col">
              <span className="p-float-label">
                <InputText
                  maxLength={50}
                  {...register("street", {
                    required: "This field is required",
                  })}
                />

                <label htmlFor="street">Street</label>
              </span>
              <small className="block pt-1">
                {errors.street && (
                  <div className="text-red-500">{errors.street.message}</div>
                )}
              </small>
            </div>
            <div className="field col">
              <span className="p-float-label">
                <InputText
                  min={0}
                  {...register("number", {
                    required: "This field is required",
                  })}
                />

                <label htmlFor="number">Name</label>
              </span>
              <small className="block pt-1">
                {errors.number && (
                  <div className="text-red-500">{errors.number.message}</div>
                )}
              </small>
            </div>
          </div>
        </form>
      )}
    </Sidebar>
  );
}
