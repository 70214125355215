import { useQuery } from "@tanstack/react-query";
import { fetchContactTelephones } from "../api/contact-telephones-api";

export function useContactTelephones(permissionCreate) {
  return useQuery({
    queryKey: ["contacttelephones"],
    queryFn: () => fetchContactTelephones(),
    retry: false,
    enabled: permissionCreate,
    onSuccess: () => {
      console.log("ALL GOOD");
    },
  });
}
