import axios from "axios";
import { toast } from "react-toastify";
import { TOAST_CONFIG } from "../utils/Constansts";
import secureLocalStorage from "react-secure-storage";

const axiosInstance = axios.create({
  baseURL:
    process.env.NODE_ENV === "development"
      ? process.env.REACT_APP_API_DEV_URL
      : process.env.REACT_APP_API_URL,
});

const updateHeader = (request) => {
  let token = JSON.parse(secureLocalStorage.getItem("accessToken"));

  request.headers.Authorization = `Bearer ${token}`;

  return request;
};

axiosInstance.interceptors.request.use((request) => {
  //console.log(request)
  if (request.url?.includes("login")) return request;

  return updateHeader(request);
});

axiosInstance.interceptors.response.use(
  (response) => {
    return response;
  },
  (error) => {
    if (error.response.status === 401) {
      toast.error(`You need to authenticate`, TOAST_CONFIG);
      window.location.href = "#/login";
    }

    if (error.response.status === 400) {
      toast.error(error.response.data.message, TOAST_CONFIG);
    }

    toast.error(error.message, TOAST_CONFIG);

    throw error;
  }
);

export default axiosInstance;
