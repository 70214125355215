import { Outlet } from "react-router-dom";
import Sidemenu from "./Sidemenu";

export const Layout = () => {
  return (
    <div className="h-full flex flex-column justify-content-between max-h-screen">
      <Sidemenu />
      <div
        className="p-4 flex-grow-1 overflow-y-auto overflow-x-hidden"
        style={{ maxHeight: "calc(100vh - 5rem)" }}
      >
        <div>
          <Outlet />
        </div>
      </div>
    </div>
  );
};

export default Layout;
