import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useDarCodes, useDarTypes } from "../hooks/darQueries";
import { useIncidentReportTypes } from "../hooks/incidentReportQueries";
import { useCitationTypes } from "../hooks/citationQueries";
import { useDispatchLogTypes } from "../hooks/dispatchLogQueries";
import { usePropertyTypes } from "../hooks/propertyQueries";
import { useRoles } from "../hooks/userQueries";

import { useLocalState } from "../hooks/useLocalState";
import { ProgressSpinner } from "primereact/progressspinner";
import { useConnStatus } from "../context/network";

import secureLocalStorage from "react-secure-storage";
import HandleFetchErrors from "../utils/HandleFetchErrors";
import { useContactTelephones } from "../hooks/useContactTelephones";
import { getHasPermission } from "../helpers/helpers";

const Loading = () => {
  const { connStatus } = useConnStatus();
  const navigate = useNavigate();
  const [userData] = useLocalState("", "userData");
  let permissions = userData[0].user.roles[0].permissions;
  const dispatchLogCreate = getHasPermission(permissions, "dispatchlog create");
  const darCreate = getHasPermission(permissions, "dar create");
  const incidentCreate = getHasPermission(permissions, "incidentreport create");
  const citationCreate = getHasPermission(permissions, "citation create");
  const propertyCreate = getHasPermission(permissions, "property create");
  const userCreate = getHasPermission(permissions, "user create");

  const {
    isSuccess: isSuccessUserRoles,
    isError: isErrorUserRoles,
    data: fetchedUserRoles,
    error: errorUserRoles,
  } = useRoles(userCreate);

  const {
    isSuccess: isSuccessContactTelephones,
    isError: isErrorContactTelephones,
    data: fetchedContactTelephones,
    error: errorContactTelephones,
  } = useContactTelephones(propertyCreate);

  const {
    isSuccess: isSuccessDarCodes,
    isError: isErrorDarCodes,
    data: fetchedDarCodes,
    error: errorDarCodes,
  } = useDarCodes(darCreate);

  const {
    isSuccess: isSuccessDarTypes,
    isError: isErrorDarTypes,
    data: fetchedDarTypes,
    error: errorDarTypes,
  } = useDarTypes(darCreate);

  const {
    isSuccess: isSuccessIncidentReportTypes,
    isError: isErrorIncidentReportTypes,
    data: fetchedIncidentReportTypes,
    error: errorIncidentReportTypes,
  } = useIncidentReportTypes(incidentCreate);

  const {
    isSuccess: isSuccessVehicleCitationTypes,
    isError: isErrorVehicleCitationTypes,
    data: fetchedVehicleCitationTypes,
    error: errorVehicleCitationTypes,
  } = useCitationTypes(citationCreate);

  const {
    isSuccess: isSuccessDispatchLogTypes,
    isError: isErrorDispatchLogTypes,
    data: fetchedDispatchLogTypes,
    error: errorDispatchLogTypes,
  } = useDispatchLogTypes(dispatchLogCreate);

  const {
    isSuccess: isSuccessPropertyTypes,
    data: fetchedPropertyTypes,
    error: errorprop,
  } = usePropertyTypes(propertyCreate);

  useEffect(() => {
    const fetchStatus = [];

    userCreate && fetchStatus.push(isSuccessUserRoles);
    dispatchLogCreate && fetchStatus.push(isSuccessDispatchLogTypes);
    darCreate && fetchStatus.push(...[isSuccessDarCodes, isSuccessDarTypes]);
    incidentCreate && fetchStatus.push(isSuccessIncidentReportTypes);
    citationCreate && fetchStatus.push(isSuccessVehicleCitationTypes);
    propertyCreate &&
      fetchStatus.push(...[isSuccessContactTelephones, isSuccessPropertyTypes]);

    const fetchedAll = fetchStatus.every((v) => v);
    const user = userData[0];

    if (fetchedAll) {
      if (user.isResident) {
        navigate("/safelistings");
      }

      navigate("/dar");
    }
  }, [
    dispatchLogCreate,
    darCreate,
    incidentCreate,
    citationCreate,
    propertyCreate,
    userCreate,
    isSuccessUserRoles,
    isSuccessContactTelephones,
    isSuccessDarCodes,
    isSuccessDarTypes,
    isSuccessIncidentReportTypes,
    isSuccessVehicleCitationTypes,
    isSuccessDispatchLogTypes,
    isSuccessPropertyTypes,
    fetchedUserRoles,
    fetchedContactTelephones,
    fetchedDarCodes,
    fetchedDarTypes,
    fetchedIncidentReportTypes,
    fetchedVehicleCitationTypes,
    fetchedDispatchLogTypes,
    fetchedPropertyTypes,
  ]);

  useEffect(() => {
    if (
      isErrorDarCodes ||
      isErrorDarTypes ||
      isErrorIncidentReportTypes ||
      isErrorVehicleCitationTypes ||
      isErrorDispatchLogTypes ||
      isErrorContactTelephones ||
      isErrorUserRoles
    ) {
      if (
        HandleFetchErrors(errorDarCodes) ||
        HandleFetchErrors(errorDarTypes) ||
        HandleFetchErrors(errorIncidentReportTypes) ||
        HandleFetchErrors(errorVehicleCitationTypes) ||
        HandleFetchErrors(errorDispatchLogTypes) ||
        HandleFetchErrors(errorContactTelephones) ||
        HandleFetchErrors(errorUserRoles) 
      ) {
        navigate("/login");
      }
    }
  }, [
    isErrorDarCodes,
    isErrorDarTypes,
    isErrorIncidentReportTypes,
    isErrorVehicleCitationTypes,
    isErrorDispatchLogTypes,
    isErrorContactTelephones,
    isErrorUserRoles
  ]);

  function setStorageItem(key, data) {
    secureLocalStorage.setItem(key, JSON.stringify(data));
  }

  useEffect(() => {
    if (isSuccessUserRoles) {
      setStorageItem("userRoles",fetchedUserRoles.data.roles);
    }
  }, [isSuccessUserRoles, fetchedUserRoles]);

  useEffect(() => {
    if (isSuccessDarCodes) {
      setStorageItem(
        "dailyActivityReportCodes",
        fetchedDarCodes.data.daily_activity_report_codes
      );
    }
  }, [isSuccessDarCodes, fetchedDarCodes]);

  useEffect(() => {
    if (isSuccessDarTypes) {
      setStorageItem(
        "dailyActivityReportTypes",
        fetchedDarTypes.data.daily_activity_report_types
      );
    }
  }, [isSuccessDarTypes, fetchedDarTypes]);

  useEffect(() => {
    if (isSuccessIncidentReportTypes) {
      setStorageItem(
        "incidentReportTypes",
        fetchedIncidentReportTypes.data.incident_report_types
      );
    }
  }, [isSuccessIncidentReportTypes, fetchedIncidentReportTypes]);

  useEffect(() => {
    if (isSuccessVehicleCitationTypes) {
      setStorageItem(
        "vehicleCitationTypes",
        fetchedVehicleCitationTypes.data.citation_types
      );
    }
  }, [isSuccessVehicleCitationTypes, fetchedVehicleCitationTypes]);

  useEffect(() => {
    if (isSuccessDispatchLogTypes) {
      setStorageItem(
        "dispatchLogTypes",
        fetchedDispatchLogTypes.data.dispatch_log_types
      );
    }
  }, [isSuccessDispatchLogTypes, fetchedDispatchLogTypes]);

  useEffect(() => {
    if (isSuccessPropertyTypes) {
      setStorageItem("propertyTypes", fetchedPropertyTypes.data.property_types);
    }
  }, [isSuccessPropertyTypes, fetchedPropertyTypes]);

  useEffect(() => {
    if (isSuccessContactTelephones) {
      setStorageItem(
        "contactTelephones",
        fetchedContactTelephones.data.contact_telephones
      );
    }
  }, [isSuccessContactTelephones, fetchedContactTelephones]);

  return (
    <>
      <div className="flex flex-wrap justify-content-center gap-2 mb-1">
        <div
          style={{
            position: "fixed",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
          }}
        >
          <ProgressSpinner
            aria-label="Loading"
            style={{ width: "10px", position: "static" }}
            strokeWidth="2"
            fill="var(--surface-ground)"
            animationDuration="1.8s"
          />
          <br />
          <span>Loading Data</span>
        </div>
      </div>
    </>
  );
};

export default Loading;
