import { useEffect } from "react";

import { useForm } from "react-hook-form";
import { Controller } from "react-hook-form";

import { useLocalState } from "../../hooks/useLocalState";

import { Sidebar } from "primereact/sidebar";

import { InputText } from "primereact/inputtext";
import { Button } from "primereact/button";
import { MultiSelect } from "primereact/multiselect";
import { Dropdown } from "primereact/dropdown";
import { classNames } from "primereact/utils";
import { toast } from "react-toastify";
import { TOAST_CONFIG } from "../../utils/Constansts";

import { useUser, useInsertUser, useUpdateUser } from "../../hooks/userQueries";
import Loader from "../loader";

const UserSheet = ({ isVisible, userId, onClose }) => {
  const [roles] = useLocalState("", "userRoles");
  const [properties] = useLocalState("", "userProperties");

  const {
    isPending: _isPending,
    isSuccess,
    isError,
    data: fetchedUser,
  } = useUser(userId);
  const user = !_isPending && userId ? fetchedUser.data.data : null;
  const isPending = userId && _isPending;

  const {
    mutateAsync: mutateInsertUser,
    data: addedUser,
    error: errorInsertUser,
    isError: isErrorInsertUser,
    isSuccess: isSuccessInsertUser,
  } = useInsertUser();

  const {
    mutateAsync: mutateUpdateUser,
    data: updatedUser,
    isSuccess: isSuccessUpdateUser,
  } = useUpdateUser();

  const {
    handleSubmit,
    control,
    formState: { errors, isSubmitting },
  } = useForm({
    values: {
      roleId: user?.role_id,
      email: user?.email,
      firstName: user?.first_name,
      lastName: user?.last_name,
      password: "",
      userName: user?.user_name,
      userProperties: user?.properties?.map((p) => p.property_id) ?? [],
    },
  });

  useEffect(() => {
    if (isSuccessInsertUser || isSuccessUpdateUser) {
      onClose();

      if (userId === null) {
        toast.success(
          `You have created USER ID: ${addedUser?.data.data.user_id}`,
          TOAST_CONFIG
        );
      } else {
        toast.success(
          `You have updaated USER ID: ${updatedUser?.data.data.user_id}`,
          TOAST_CONFIG
        );
      }
    }
  }, [addedUser, updatedUser]);

  const onSubmit = async (data) => {
    try {
      console.log(data);
      const userData = {
        role_id: data.roleId,
        status: data.status,
        email: data.email,
        first_name: data.firstName,
        last_name: data.lastName,
        user_name: data.userName,
        properties: data.userProperties.map((p) => ({ property_id: p })),
      };

      if (userId === null) {
        await mutateInsertUser({ json: userData, persistOnStorage: true });
      } else {
        userData["user_id"] = userId;
        await mutateUpdateUser(userData);
      }
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <>
      <Sidebar
        visible={isVisible}
        position="right"
        onHide={onClose}
        className="w-full md:w-6 lg:w-5"
      >
        {isPending ? (
          <Loader />
        ) : (
          <>
            <h2>{userId ? "Update User" : "New User"}</h2>
            <form className="p-fluid" onSubmit={handleSubmit(onSubmit)}>
              <div className="field ">
                <span className="p-float-label">
                  <Controller
                    control={control}
                    name="roleId"
                    rules={{
                      validate: (value) =>
                        value !== undefined || "You must select a role",
                      valueAsNumber: true,
                    }}
                    render={({ field }) => (
                      <Dropdown
                        id={field.name}
                        filter
                        placeholder="Select a role"
                        optionLabel="name"
                        optionValue="id"
                        options={roles}
                        {...field}
                      />
                    )}
                  />
                  <label htmlFor="role">Role</label>
                </span>
                <small className="block pt-1">
                  {errors.roleId && (
                    <div className="text-red-500">{errors.roleId.message}</div>
                  )}
                </small>
              </div>

              <div className="field ">
                <span className="p-float-label">
                  <Controller
                    control={control}
                    name="userProperties"
                    className="white-space-nowrap overflow-hidden text-overflow-ellipsis"
                    render={({ field }) => (
                      <MultiSelect
                        id={field.name}
                        placeholder="Select contact telephone(s)"
                        optionLabel="name"
                        optionValue="property_id"
                        // optionValue="contact_telephone_id"
                        //optionGroupLabel="property_type['type']"
                        //optionGroupChildren="name"
                        options={properties}
                        maxSelectedLabels={3}
                        showClear={true}
                        showSelectAll={true}
                        className="white-space-nowrap overflow-hidden text-overflow-ellipsis"
                        display="chip"
                        {...field}
                      />
                    )}
                  />
                  <label htmlFor="userProperties">Properties</label>
                </span>
                <small className="block pt-1">
                  {errors.userProperties && (
                    <div className="text-red-500">
                      {errors.userProperties.message}
                    </div>
                  )}
                </small>
              </div>

              <div className="field">
                <div className="formgrid grid">
                  <div className="field col">
                    <span className="p-float-label">
                      <Controller
                        name="firstName"
                        control={control}
                        rules={{ required: "Name is required." }}
                        render={({ field, fieldState }) => (
                          <InputText
                            id={field.name}
                            {...field}
                            value={field.value ?? ""}
                            autoFocus
                            maxLength={50}
                            className={classNames({
                              "p-invalid": fieldState.invalid,
                            })}
                          />
                        )}
                      />

                      <label htmlFor="firstName">First Name</label>
                      {errors.firstName && (
                        <div className="text-red-500">
                          {errors.firstName.message}
                        </div>
                      )}
                    </span>
                  </div>

                  <div className="field col">
                    <span className="p-float-label">
                      <Controller
                        name="lastName"
                        control={control}
                        rules={{ required: "Name is required." }}
                        render={({ field, fieldState }) => (
                          <InputText
                            id={field.name}
                            {...field}
                            value={field.value ?? ""}
                            autoFocus
                            maxLength={50}
                            className={classNames({
                              "p-invalid": fieldState.invalid,
                            })}
                          />
                        )}
                      />

                      <label htmlFor="lastName">Last Name</label>
                      {errors.lastName && (
                        <div className="text-red-500">
                          {errors.lastName.message}
                        </div>
                      )}
                    </span>
                  </div>
                </div>
              </div>

              <div className="field">
                <div className="formgrid grid">
                  <div className="field col">
                    <span className="p-float-label">
                      <Controller
                        name="email"
                        control={control}
                        rules={{ required: "Name is required." }}
                        render={({ field, fieldState }) => (
                          <InputText
                            id={field.name}
                            {...field}
                            value={field.value ?? ""}
                            autoFocus
                            maxLength={50}
                            className={classNames({
                              "p-invalid": fieldState.invalid,
                            })}
                          />
                        )}
                      />

                      <label htmlFor="email">Email</label>
                      {errors.email && (
                        <div className="text-red-500">
                          {errors.email.message}
                        </div>
                      )}
                    </span>
                  </div>

                  <div className="field col">
                    <span className="p-float-label">
                      <Controller
                        name="userName"
                        control={control}
                        rules={{ required: "Username is required." }}
                        render={({ field, fieldState }) => (
                          <InputText
                            id={field.name}
                            {...field}
                            value={field.value ?? ""}
                            autoFocus
                            maxLength={50}
                            className={classNames({
                              "p-invalid": fieldState.invalid,
                            })}
                          />
                        )}
                      />

                      <label htmlFor="userName">User Name</label>
                      {errors.userName && (
                        <div className="text-red-500">
                          {errors.userName.message}
                        </div>
                      )}
                    </span>
                  </div>
                </div>
              </div>

              <Button
                disabled={isSubmitting}
                role="submit"
                label={isSubmitting ? "Loading..." : "Submit"}
                className="mt-2"
              />
              {errors.root && (
                <div className="text-red-500">{errors.root.message}</div>
              )}
            </form>
          </>
        )}
      </Sidebar>
    </>
  );
};

export default UserSheet;
