import {
  useMutation,
  useQuery,
  useQueryClient,
  useSuspenseQuery,
} from "@tanstack/react-query";
import {
  fetchDars,
  fetchDar,
  fetchDarCodes,
  fetchDarTypes,
  addDar,
  updateDar,
  deleteDar,
} from "../api/darApi";

export function useDarCodes(darCreate) {
  return useQuery({
    queryKey: ["darcodes", darCreate],
    queryFn: () => fetchDarCodes(),
    retry: false,
    enabled: darCreate,
    throwOnError: (error) => {
      console.log("ALL BAD");
      //console.error(error.message)
    },
  });
}

export function useDarTypes(darCreate) {
  return useQuery({
    queryKey: ["dartypes", darCreate],
    queryFn: () => fetchDarTypes(),
    retry: false,
    enabled: darCreate,
    throwOnError: (error) => {
      console.log(error);
      //console.error(error.message)
    },
  });
}

export function useDars(rows, page, propertyId) {
  return useQuery({
    queryKey: ["dars", { rows, page, propertyId }],
    queryFn: () => fetchDars(rows, page, propertyId),
    retry: false,
    //staleTime: 10,
    refetchInterval: 400000,

    onSuccess: (data) => {
      return data;
    },
    throwOnError: (error) => {
      console.log("ALL BAD");
      //return error
      //console.error(error.message)
    },
  });
}

export function useDar(darId) {
  return useQuery({
    queryKey: ["dar", { darId }],
    queryFn: () => fetchDar(darId),
    enabled: Boolean(darId),
    retry: false,
    onSuccess: () => {
      console.log("ALL GOOD");
    },
    onError: (error) => {
      console.log("ALL BAD");
      console.error(error.message);
    },
  });
}

export const useInsertDar = () => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: addDar,
    networkMode: "offlineFirst",
    retry: false,
    throwOnError: false,
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: ["dars"],
        //exact: true,
        refetchActive: true,
        refetchInactive: false,
      });
    },
    onError: (error) => {
      console.log(error);
    },
  });
};

export function useUpdateDar() {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: updateDar,
    //networkMode: offlineFirst,
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: ["dars"],
        //exact: true,
        refetchActive: true,
        refetchInactive: false,
      });
    },
    onError: () => {
      console.log("error occured");
    },
  });
}

export function useDeleteDar() {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: deleteDar,

    onSuccess() {
      queryClient.invalidateQueries({
        queryKey: ["dars"],
        //exact: true,
        refetchActive: true,
        refetchInactive: false,
      });
    },
  });
}
