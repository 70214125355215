import { useState } from "react";
import { useSectionPermissions } from "../../hooks/useSectionPermissions";
import Datatable from "./datatable";
import DarSheet from "./dar-sheet";
import { DarInfoModal } from "./dar-info-modal";
import { Avatar } from "primereact/avatar";
import { Button } from "primereact/button";
import { ScrollTop } from "primereact/scrolltop";

import { useQueryClient } from "@tanstack/react-query";
import { Card } from "primereact/card";

const MODAL_KEYS = {
  SHEET: "sheet",
  MODAL: "modal",
};

const Dar = () => {
  const [modalKeyOpen, setModalKeyOpen] = useState(null);
  const [darId, setDarId] = useState(null);
  const queryClient = useQueryClient();

  const permissionsObject = useSectionPermissions("dar", [
    "create",
    "show",
    "update",
    "delete",
  ]);

  const handleRefresh = () => {
    queryClient.invalidateQueries({
      queryKey: ["dars"],
      refetchActive: true,
      refetchInactive: false,
    });
  };

  const handleOpenSheet = (darId) => {
    setModalKeyOpen(MODAL_KEYS.SHEET);
    if (darId) setDarId(darId);
  };

  const handleShowDetail = (darId) => {
    setModalKeyOpen(MODAL_KEYS.MODAL);
    if (darId) setDarId(darId);
  };

  const handleCloseModal = () => {
    setModalKeyOpen(null);
    setDarId(null);
  };

  return (
    <>
      <Card>
        <div className="flex flex-wrap justify-content-between gap-2 mb-1">
          <div className=" p-toolbar-group-start p-toolbar-group-left">
            <Avatar
              label="DAR"
              size="large"
              style={{ backgroundColor: "white" }}
            />
            <Button
              icon="pi pi-refresh"
              rounded
              text
              aria-label="Refresh"
              tooltip="Refresh"
              onClick={handleRefresh}
            />
          </div>
          {permissionsObject.create ? (
            <Button
              label="New Dar"
              id="newBtn"
              onClick={() => handleOpenSheet()}
            />
          ) : null}
        </div>
        <Datatable
          permissionsObject={permissionsObject}
          onShowDetail={handleShowDetail}
          onEdit={handleOpenSheet}
        />
        <ScrollTop
          target="parent"
          threshold={100}
          className="w-2rem h-2rem border-round bg-primary"
          icon="pi pi-arrow-up text-base"
        />
      </Card>

      {modalKeyOpen === MODAL_KEYS.MODAL ? (
        <DarInfoModal isVisible darId={darId} onClose={handleCloseModal} />
      ) : null}
      {modalKeyOpen === MODAL_KEYS.SHEET ? (
        <DarSheet isVisible darId={darId} onClose={handleCloseModal} />
      ) : null}
    </>
  );
};

export default Dar;
