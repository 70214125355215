import { useState, useCallback, useEffect } from "react";

const MANIFEST = "/manifest.json";
const POLL_INTERVAL = 1000 * 6 * 10 * 15; // 10 minutes

export const useVersionChecker = () => {
  const [isNewVersionAvailable, setIsNewVersionAvailable] = useState(false);

  useEffect(() => {
    const getLatestVersion = async () => {
      const response = await fetch(MANIFEST);
      const manifest = await response.json();

      return manifest.version;
    };

    const init = async () => {
      try {
        const currentVersion = await getLatestVersion();
        localStorage.setItem("tend-version", currentVersion);
      } catch (ex) {
        // log to sentry / or something
      } finally {
        setTimeout(poll, POLL_INTERVAL);
      }
    };

    const poll = async () => {
      try {
        const currentVersion = localStorage.getItem("tend-version");
        const latestVersion = await getLatestVersion();

        if (currentVersion && currentVersion !== latestVersion) {
          setIsNewVersionAvailable(true);
        }
      } catch (ex) {
        // log somewhere
      } finally {
        setTimeout(poll, POLL_INTERVAL);
      }
    };

    if (process.env.NODE_ENV === "production") {
      init();
    }
  }, []);

  const reloadPage = useCallback(() => {
    setIsNewVersionAvailable(false);
    window.location.reload();
  }, []);

  return { isNewVersionAvailable, reloadPage };
};
