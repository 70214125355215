import React from "react";
import { useGeolocated } from "react-geolocated";
import PositionError from "../components/PositionError/PositionError";

const PositionContext = React.createContext();
PositionContext.displayName = "PositionContext";

const PositionProvider = ({ children }) => {
  const { coords, isGeolocationAvailable, isGeolocationEnabled, getPosition } =
    useGeolocated({
      positionOptions: {
        enableHighAccuracy: true,
        maximumAge: 0,
        timeout: Infinity,
        watchPosition: true,
      },
    });

  if (isGeolocationAvailable && isGeolocationEnabled) {
    return (
      <PositionContext.Provider value={{ position: coords, getPosition }}>
        {children}
      </PositionContext.Provider>
    );
  }

  return <PositionError />;
};

const usePosition = () => {
  const context = React.useContext(PositionContext);
  if (context === undefined) {
    throw new Error("usePosition must be used within a PositionProvider");
  }
  return context;
};

export { usePosition };
export default PositionProvider;
