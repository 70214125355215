import { ProgressSpinner } from "primereact/progressspinner";

export default function Loader() {
  return (
    <div className="flex flex-wrap h-18rem justify-content-between gap-2 mb-1">
      <div
        className="h-6rem"
        style={{
          position: "absolute",
          top: "50%",
          left: "50%",
          transform: "translate(-50%, -50%)",
        }}
      >
        <ProgressSpinner
          aria-label="Loading"
          style={{ width: "50px", height: "100vm" }}
          strokeWidth="2"
          fill="var(--surface-ground)"
          animationDuration=".5s"
        />

        <p>Loading</p>
      </div>
    </div>
  );
}
