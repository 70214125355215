import { useEffect, useState } from "react";
import { useUpdateEffect } from "primereact/hooks";

import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { Tag } from "primereact/tag";
import { Button } from "primereact/button";
import { confirmDialog } from "primereact/confirmdialog";

import { toast } from "react-toastify";
import * as moment from "moment";

import { TOAST_CONFIG } from "../../utils/Constansts";

import { utcToLocale } from "../../helpers/helpers";
import { useConnStatus } from "../../context/network";
import { useProperty } from "../../context/PropertyProvider";
import { useHouseholds } from "../../hooks/houseHoldQueries";

const darDateBodyTemplate = (dar) => {
  return moment(utcToLocale(dar.dar_date)).format("yyyy-MM-DD HH:mm");
};

const typeBodyTemplate = (dar) => {
  if (dar.code_type === "Red") {
    return (
      <Tag
        severity="danger"
        className="w-10rem md:w-12rem lg:w-14rem "
        text="white"
        style={{
          padding: "0.4rem",
          backgroundColor: "#d32f2fd1",
          textAlign: "center",
        }}
      >
        {dar.type}
      </Tag>
    );
  } else if (dar.code_type === "Yellow") {
    return (
      <Tag
        severity="warning"
        className="w-10rem md:w-12rem lg:w-14rem"
        text="black"
        style={{
          padding: "0.4rem",
          backgroundColor: "#fbc02dd1",
          textAlign: "center",
        }}
      >
        {dar.type}
      </Tag>
    );
  } else
    return (
      <Tag
        severity="success"
        className="w-10rem md:w-12rem lg:w-14rem"
        text="white"
        style={{
          padding: "0.4rem",
          backgroundColor: "#689f38d1",
          textAlign: "center",
        }}
      >
        {dar.type}
      </Tag>
    );
};

const mapBodyTemplate = (dar) => {
  if (dar.include_gps_coordinates == 1) {
    return (
      <>
        <a
          href={`https://www.google.com/maps/search/?api=1&query=${dar.gps_coordinates}`}
          target="_blank"
          rel="noopener noreferrer"
        >
          <Button icon="pi pi-map-marker" rounded link text />
        </a>
      </>
    );
  }
};

export default function Datatable({ permissionsObject, onShowDetail, onEdit }) {
  const [property] = useProperty();
  const { connStatus } = useConnStatus();

  let [lazyState, setlazyState] = useState({
    first: 0,
    rows: 10,
    page: 1,
    sortField: "house_hold_id",
    sortOrder: 0,
    filters: {
      house_hold_id_format: { value: "", matchMode: "contains" },
    },
  });

  const { isLoading, isPending, isError, data, error } = useHouseholds(
    lazyState.rows,
    lazyState.page,
    property
  );

  const households =
    isLoading || isPending || isError ? [] : data.data.households.data;

  // const {
  //   mutate: mutateDeleteDar,
  //   data: deletedDar,
  //   error: errorDeleteDar,
  //   isSuccess: isSuccessDeleteDar,
  // } = useDeleteDar();

  const totalRecords = data?.data.households.total ?? 0;
  const from = data?.data.households.from ?? 0;
  const to = data?.data.households.to ?? 0;

  useUpdateEffect(() => {
    if (isError) {
      toast.error(error.code, TOAST_CONFIG);
    }
  }, [isError]);

  // useUpdateEffect(() => {
  //   if (isSuccessDeleteDar) {
  //     toast.success(`You have delete DAR`, TOAST_CONFIG);
  //   }

  //   if (errorDeleteDar) {
  //     console.log(errorDeleteDar);
  //   }
  // }, [deletedDar]);

  useEffect(() => {
    setlazyState((old) => ({
      ...old,
      first: 0,
      page: 1,
      rows: 10,
    }));
  }, [property]);

  // const editBodyTemplate = (dar) => {
  //   if (permissionsObject.update) {
  //     if (dar.automatic_dar == 0) {
  //       return (
  //         <Button
  //           severity="info"
  //           icon="pi pi-pencil"
  //           size="small"
  //           onClick={() =>
  //             connStatus
  //               ? onEdit(dar.daily_activity_report_id)
  //               : noConectionAlert()
  //           }
  //         />
  //       );
  //     } else {
  //       return (
  //         <Button severity="info" icon="pi pi-pencil" size="small" disabled />
  //       );
  //     }
  //   }
  // };

  // const deleteBodyTemplate = (dar) => {
  //   if (permissionsObject.delete) {
  //     if (dar.automatic_dar == 0) {
  //       //console.log(`allowedOfflineAction 'false' ${connStatus.isOnline}`)
  //       return (
  //         <>
  //           <Button
  //             icon="pi pi-trash"
  //             onClick={() =>
  //               connStatus
  //                 ? confirmDelete(dar.daily_activity_report_id)
  //                 : noConectionAlert()
  //             }
  //             severity="danger"
  //             size="small"
  //           />
  //         </>
  //       );
  //     } else {
  //       return (
  //         <>
  //           <Button
  //             icon="pi pi-trash"
  //             severity="danger"
  //             size="small"
  //             disabled
  //           />
  //         </>
  //       );
  //     }
  //   }
  // };

  // const noConectionAlert = () => {
  //   toast.warn(
  //     "Action not allowed while you don't have internet connection",
  //     TOAST_CONFIG
  //   );
  // };

  // const confirmDelete = (id) => {
  //   const idToDelete = id;

  //   confirmDialog({
  //     key: 1,
  //     message: "Do you want to delete this record?",
  //     header: "Delete Confirmation",
  //     icon: "pi pi-info-circle",
  //     defaultFocus: "reject",
  //     acceptClassName: "p-button-danger",
  //     accept: () => mutateDeleteDar(idToDelete),
  //     reject: () => toast.warn("You have rejected", TOAST_CONFIG),
  //   });
  // };

  // const isSelectable = (data) => true; //data.automatic_dar == 0;

  // const isRowSelectable = (event) =>
  //   event.data ? isSelectable(event.data) : true;

  const onPage = (event) => {
    event.page = event.page + 1;
    setlazyState(event);
  };

  // const onSort = (event) => {
  //   setlazyState(event);
  // };

  // const onFilter = (event) => {
  //   console.log(event);
  //   event["first"] = 0;
  //   setlazyState(event);
  // };

  const footer = () => {
    if (totalRecords > 0) {
      return `Showing: ${from} - ${to} of ${totalRecords}`;
    }
    return null;
  };

  return (
    <>
      <DataTable
        className={"h-fit hide-on-print"}
        resizableColumns
        columnResizeMode="expand"
        value={households}
        lazy
        dataKey="house_hold_id"
        paginator
        footer={footer}
        size="small"
        stripedRows
        scrollable
        // onRowSelect={(e) =>
        //   connStatus
        //     ? onShowDetail(e.data.daily_activity_report_id)
        //     : noConectionAlert()
        // }
        selectionMode="single"
        metaKeySelection={false}
        first={lazyState.first}
        rows={lazyState.rows}
        totalRecords={totalRecords}
        onPage={onPage}
        // onSort={onSort}
        sortField={lazyState.sortField}
        sortOrder={lazyState.sortOrder}
        rowsPerPageOptions={[5, 10, 25, 50]}
        // onFilter={onFilter}
        filters={lazyState.filters}
        loading={isLoading}
        emptyMessage="No data available"
        tableStyle={{ minWidth: "50rem" }}
        // isDataSelectable={isRowSelectable}
      >
        <Column
          field="name"
          header="Name"
          // className=" w-rem md:w-9rem lg:w-11rem "
          style={{ whiteSpace: "unset" }}
        />

        <Column
          field="created_at"
          header="Created At"
          // className=" w-8rem md:w-12rem lg:w-14rem "
          body={darDateBodyTemplate}
          style={{ whiteSpace: "unset" }}
        />

        <Column
          // field="user_name"
          header="Address"
          filterPlaceholder="Search"
          // className=" w-rem md:w-9rem lg:w-11rem "
          style={{ whiteSpace: "unset" }}
          body={(h) => `${h.number} ${h.street}`}
        />

        <Column
          header="Property"
          // hidden
          body={(h) => h.property_name}
          // filterPlaceholder="Search"
        />

        {/* <Column header="" body={editBodyTemplate} className="w-1rem" />
        <Column header="" body={deleteBodyTemplate} className="w-1rem" /> */}
      </DataTable>
    </>
  );
}
