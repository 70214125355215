import { Dialog } from "primereact/dialog";
import { Button } from "primereact/button";
import { Accordion, AccordionTab } from "primereact/accordion";
import { Badge } from "primereact/badge";
import Loader from "../loader";
import { useProperty } from "../../hooks/propertyQueries";

const ruleValues = {
  permits_period_by: ["Rolling window", "Calendar month", "Calendar year"],
  citations_by: ["Household", "Plate"],
  citations_apply_by: {
    1: "Individual rules",
    2: "All the same",
  },
  citation_period_by: ["Rolling window", "Calendar month", "Calendar year"],
};

export default function ShowModal({ isVisible, propertyId, onClose }) {
  const { isLoading, data } = useProperty(propertyId);
  const property = isLoading ? {} : data.data.data;

  return (
    <Dialog
      //className="sm:w-30rem md:w-40rem lg:w-50rem"
      className="w-11 sm:w-8 md:w-7 lg:w-7 xl:w-6 h-auto"
      showHeader={false}
      contentStyle={{ borderTopLeftRadius: "4px", borderTopRightRadius: "4px" }}
      dismissableMask
      closeOnEscape
      visible={isVisible}
      onHide={onClose}
    >
      {isLoading ? (
        <Loader />
      ) : (
        <div className="p-4">
          <div className="flex justify-content-between align-items-center mb-4">
            <div className="flex flex-column gap-2">
              <h2 className="m-0">Property {property.name}</h2>
              <span className="text-sm">{property.type}</span>
            </div>
            <Button icon="pi pi-times" rounded link text onClick={onClose} />
          </div>
          <Accordion>
            <AccordionTab
              header="General information"
              headerClassName="p-0 border-solid border-round border-1 border-200"
              contentClassName="border-solid border-round border-1 border-200 border-top-none"
              pt={{
                headerAction: {
                  className:
                    "surface-ground font-semibold text-lg text-gray-800",
                },
              }}
            >
              <div className="flex flex-column gap-4">
                <p>
                  <span className="font-semibold">Address:</span>{" "}
                  {property.number} {property.street}, {property.city},{" "}
                  {property.zip_code}
                </p>
                <div className="flex flex-column align-items-start">
                  <span className="font-semibold">Contact:</span>
                  <div className="flex flex-column gap-1">
                    <span>{property.contact_name}</span>
                    <a href={`tel:+${property.telephone}`}>
                      {property.telephone}
                    </a>
                    <a href={`mailto:${property.email}`} target="_blank">
                      {property.email}
                    </a>
                  </div>
                </div>
                <div className="flex flex-column align-items-start gap-2">
                  <span className="font-semibold">Contact telephones:</span>{" "}
                  {property.contact_telephones.length ? (
                    <div>
                      {property.contact_telephones.map((t) => (
                        <div
                          key={`concat-telephone-${t.contact_telephone_id}`}
                          className="flex flex-column align-items-start mb-3"
                        >
                          <small>{t.type}</small>
                          <a href={`tel:+${t.telephone}`}>
                            {t.name} (+${t.telephone})
                          </a>
                        </div>
                      ))}
                    </div>
                  ) : (
                    "-"
                  )}
                </div>
                <div className="flex align-items-start gap-2">
                  <span className="font-semibold">Maintenance contact:</span>
                  <div className="flex flex-column gap-1">
                    <a
                      href={`mailto:${property.maintenance_email}`}
                      target="_blank"
                    >
                      {property.maintenance_email}
                    </a>
                  </div>
                </div>
              </div>
            </AccordionTab>
            <AccordionTab
              header="Sections"
              headerClassName="p-0 border-solid border-round border-1 border-200"
              contentClassName="border-solid border-round border-1 border-200 border-top-none"
              pt={{
                headerAction: {
                  className:
                    "surface-ground font-semibold text-lg text-gray-800",
                },
              }}
            >
              <div>
                <span className="font-semibold mb-2 block">
                  Active sections
                </span>

                <div className="flex gap-2 flex-wrap">
                  {property.property_hired_types
                    .sort((a, b) =>
                      a.label.toLowerCase() > b.label.toLowerCase() ? 1 : -1
                    )
                    .map((t) => (
                      <Badge
                        key={`section-${t.property_hired_type_id}`}
                        value={t.label}
                      />
                    ))}
                </div>
              </div>
            </AccordionTab>
            <AccordionTab
              header="Rules"
              headerClassName="p-0 border-solid border-round border-1 border-200"
              contentClassName="border-solid border-round border-1 border-200 border-top-none"
              pt={{
                headerAction: {
                  className:
                    "surface-ground font-semibold text-lg text-gray-800",
                },
              }}
            >
              <div>
                {property.rules.map((r) => (
                  <p key={`rule-${r.property_rule_id}`}>
                    <span className="font-semibold">{r.rule}:</span>{" "}
                    {r.type === "number"
                      ? r.value
                      : ruleValues[r.key]?.[r.value]}
                  </p>
                ))}
              </div>
            </AccordionTab>
            <AccordionTab
              header="Citations"
              headerClassName="p-0 border-solid border-round border-1 border-200"
              contentClassName="border-solid border-round border-1 border-200 border-top-none"
              pt={{
                headerAction: {
                  className:
                    "surface-ground font-semibold text-lg text-gray-800",
                },
              }}
            >
              <div className="flex flex-column gap-4">
                {property.property_citation_types
                  .filter((c) => c.type !== "range")
                  .map((c) => (
                    <span key={`citation-${c.citation_type_id}`}>
                      {c.type === "numeric"
                        ? c.name.replace("_ _ _", c.value)
                        : c.name}
                    </span>
                  ))}
                {property.property_citation_types
                  .filter((c) => c.type === "range")
                  .map((c) => (
                    <span key={`citation-${c.citation_type_id}`}>
                      {c.name.replace("_ _ _", c.from).replace("_ _ _", c.to)}
                    </span>
                  ))}
              </div>
            </AccordionTab>
            <AccordionTab
              header="Files"
              headerClassName="p-0 border-solid border-round border-1 border-200"
              contentClassName="border-solid border-round border-1 border-200 border-top-none"
              pt={{
                headerAction: {
                  className:
                    "surface-ground font-semibold text-lg text-gray-800",
                },
              }}
            >
              <div className="flex flex-column gap-2">
                {property.files.map((f) => (
                  <a
                    key={`file-${f.url}`}
                    href={f.url}
                    target="_blank"
                    className="link font-semibold p-2"
                    download
                  >
                    {decodeURIComponent(f.name)}
                  </a>
                ))}
              </div>
            </AccordionTab>
          </Accordion>
        </div>
      )}
    </Dialog>
  );
}
