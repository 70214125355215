import { Dropdown } from "primereact/dropdown";
import { InputSwitch } from "primereact/inputswitch";
import { InputText } from "primereact/inputtext";
import { InputTextarea } from "primereact/inputtextarea";
import { Message } from "primereact/message";
import { Controller } from "react-hook-form";
import { classNames } from "primereact/utils";
import { Chip } from "primereact/chip";
import { MultiSelect } from "primereact/multiselect";

export default function InfoPanel({
  control,
  propertyTypes,
  contactTelephones,
  errors,
  inputFilesRef,
  isPropertyManager,
  files,
  onLoadFiles,
  onRemoveFile,
}) {
  const telephoneOptions = contactTelephones.reduce((acc, t) => {
    let group = acc.find((g) => g.label === t.type);

    if (!group) {
      group = {
        label: t.type,
        items: [],
      };
      acc.push(group);
    }

    group.items.push({
      label: `${t.name} (${t.telephone})`,
      value: t.contact_telephone_id,
    });

    return acc;
  }, []);

  return (
    <>
      <div className="field">
        <div className="formgrid grid">
          <div className="field col">
            <span className="p-float-label">
              <Controller
                name="name"
                control={control}
                rules={{ required: "Property Name is required." }}
                render={({ field, fieldState }) => (
                  <InputText
                    id={field.name}
                    {...field}
                    // value={field.value ?? ""}
                    autoFocus
                    maxLength={50}
                    className={classNames({
                      "p-invalid": fieldState.invalid,
                    })}
                  />
                )}
              />

              <label htmlFor="name">Property Name</label>
            </span>
            <small className="block pt-1">
              {errors.name && (
                <div className="text-red-500">{errors.name.message}</div>
              )}
            </small>
          </div>

          <div className="field col">
            <span className="p-float-label">
              <Controller
                control={control}
                name="property_type_id"
                rules={{
                  required: "You must select a type",
                }}
                render={({ field }) => (
                  <Dropdown
                    id={field.name}
                    placeholder="Select a type"
                    optionValue="property_type_id"
                    optionLabel="type"
                    options={propertyTypes}
                    {...field}
                  />
                )}
              />
              {errors.property_type_id && (
                <div className="text-red-500">
                  {errors.property_type_id.message}
                </div>
              )}

              <label htmlFor="type">Type</label>
            </span>
          </div>
        </div>
      </div>

      <div className="field">
        <div className="formgrid grid">
          <div className="field col">
            <span className="p-float-label">
              <Controller
                name={"street"}
                control={control}
                rules={{ required: "Street is required.", min: 0 }}
                render={({ field, fieldState }) => (
                  <InputText
                    id={field.name}
                    {...field}
                    maxLength={50}
                    className={classNames({
                      "p-invalid": fieldState.invalid,
                    })}
                  />
                )}
              />

              <label htmlFor="street">Street</label>
            </span>
            <small className="block pt-1">
              {errors.street && (
                <div className="text-red-500">{errors.street.message}</div>
              )}
            </small>
          </div>

          <div className="field col">
            <span className="p-float-label">
              <Controller
                name={"number"}
                control={control}
                rules={{ required: "Number is required.", min: 0 }}
                render={({ field, fieldState }) => (
                  <InputText
                    id={field.name}
                    {...field}
                    maxLength={50}
                    className={classNames({
                      "p-invalid": fieldState.invalid,
                    })}
                  />
                )}
              />

              <label htmlFor="number">Number</label>
            </span>
            <small className="block pt-1">
              {errors.number && (
                <div className="text-red-500">{errors.number.message}</div>
              )}
            </small>
          </div>
        </div>
      </div>

      <div className="field">
        <div className="formgrid grid">
          <div className="field col">
            <span className="p-float-label">
              <Controller
                name={"city"}
                control={control}
                rules={{ required: "city is required.", min: 0 }}
                render={({ field, fieldState }) => (
                  <InputText
                    id={field.name}
                    {...field}
                    maxLength={50}
                    className={classNames({
                      "p-invalid": fieldState.invalid,
                    })}
                  />
                )}
              />

              <label htmlFor="city">City</label>
            </span>
            <small className="block pt-1">
              {errors.city && (
                <div className="text-red-500">{errors.city.message}</div>
              )}
            </small>
          </div>
          <div className="field col">
            <span className="p-float-label">
              <Controller
                name="zip_code"
                control={control}
                rules={{ required: "ZIP code is required." }}
                render={({ field, fieldState }) => (
                  <InputText
                    id={field.name}
                    {...field}
                    maxLength={50}
                    className={classNames({
                      "p-invalid": fieldState.invalid,
                    })}
                  />
                )}
              />

              <label htmlFor="zip_code">ZIP code</label>
            </span>
            <small className="block pt-1">
              {errors.zip_code && (
                <div className="text-red-500">{errors.zip_code.message}</div>
              )}
            </small>
          </div>
        </div>
      </div>

      <div className="field">
        <span className="p-float-label">
          <Controller
            name="gps_coordinates"
            control={control}
            rules={{ required: "GPS Coordinates is required." }}
            render={({ field, fieldState }) => (
              <InputText
                id={field.name}
                {...field}
                maxLength={50}
                className={classNames({
                  "p-invalid": fieldState.invalid,
                })}
              />
            )}
          />

          <label htmlFor="gps_coordinates">GPS Coordinates</label>
        </span>
        <small className="block pt-1">
          {errors.gps_coordinates && (
            <div className="text-red-500">{errors.gps_coordinates.message}</div>
          )}
        </small>
      </div>

      <div className="field">
        <span className="p-float-label">
          <Controller
            name="contact_name"
            control={control}
            rules={{ required: "Contact Name is required." }}
            render={({ field, fieldState }) => (
              <InputText
                id={field.name}
                {...field}
                maxLength={50}
                className={classNames({
                  "p-invalid": fieldState.invalid,
                })}
              />
            )}
          />

          <label htmlFor="name">Contact Name</label>
        </span>
        <small className="block pt-1">
          {errors.contact_name && (
            <div className="text-red-500">{errors.contact_name.message}</div>
          )}
        </small>
      </div>

      <div className="field">
        <div className="formgrid grid">
          <div className="field col">
            <span className="p-float-label">
              <Controller
                name={"telephone"}
                control={control}
                rules={{
                  required: "Contact Telephone is required.",
                  min: 0,
                }}
                render={({ field, fieldState }) => (
                  <InputText
                    id={field.name}
                    {...field}
                    maxLength={50}
                    className={classNames({
                      "p-invalid": fieldState.invalid,
                    })}
                  />
                )}
              />

              <label htmlFor="telephone">Contact Telephone</label>
            </span>
            <small className="block pt-1">
              {errors.telephone && (
                <div className="text-red-500">{errors.telephone.message}</div>
              )}
            </small>
          </div>

          <div className="field col">
            <span className="p-float-label">
              <Controller
                name="email"
                control={control}
                rules={{ required: "Contact Email is required." }}
                render={({ field, fieldState }) => (
                  <InputText
                    id={field.name}
                    {...field}
                    maxLength={50}
                    className={classNames({
                      "p-invalid": fieldState.invalid,
                    })}
                  />
                )}
              />

              <label htmlFor="email">Contact Email</label>
            </span>
            <small className="block pt-1">
              {errors.email && (
                <div className="text-red-500">{errors.email.message}</div>
              )}
            </small>
          </div>
        </div>
      </div>

      <div className="field">
        {!isPropertyManager ? (
          <Message
            severity="warn"
            className=" w-full justify-content-start mb-3 p-2"
            text="Remember that you have to assign a Property Manager to the property from the user section"
          />
        ) : null}
        <div className="flex gap-2">
          <Controller
            control={control}
            name="contact_is_property_manager"
            render={({ field }) => (
              <InputSwitch checked={field.value} {...field} />
            )}
          />

          <label htmlFor="contact_is_property_manager" className="ml-2">
            The contact is the Property Manager
          </label>
        </div>
        <small className="block pt-1">
          {errors.contact_is_property_manager && (
            <div className="text-red-500">
              {errors.contact_is_property_manager.message}
            </div>
          )}
        </small>
      </div>

      <div className="field">
        <span className="p-float-label">
          <Controller
            control={control}
            name="contact_telephones"
            className="white-space-nowrap overflow-hidden text-overflow-ellipsis"
            render={({ field }) => (
              <MultiSelect
                id={field.name}
                placeholder="Select contact telephone(s)"
                optionLabel="label"
                // optionValue="contact_telephone_id"
                optionGroupLabel="label"
                optionGroupChildren="items"
                options={telephoneOptions}
                className="white-space-nowrap overflow-hidden text-overflow-ellipsis"
                display="chip"
                {...field}
              />
            )}
          />
          <label htmlFor="type">Contact telephones</label>
        </span>
        <small className="block pt-1">
          {errors.contact_telephones && (
            <div className="text-red-500">
              {errors.contact_telephones.message}
            </div>
          )}
        </small>
      </div>

      <div className="field col">
        <span className="p-float-label">
          <Controller
            name={"maintenance_email"}
            control={control}
            rules={{
              required: "Maintenance email is required.",
              min: 0,
            }}
            render={({ field, fieldState }) => (
              <InputText
                id={field.name}
                {...field}
                maxLength={50}
                className={classNames({
                  "p-invalid": fieldState.invalid,
                })}
              />
            )}
          />

          <label htmlFor="maintenance_email">Maintenance email</label>
        </span>
        <small className="block pt-1">
          {errors.maintenance_email && (
            <div className="text-red-500">
              {errors.maintenance_email.message}
            </div>
          )}
        </small>
      </div>

      <div className="field">
        <span className="p-float-label">
          <Controller
            name="notes"
            control={control}
            rules={{ required: "Notes is required." }}
            render={({ field, fieldState }) => (
              <InputTextarea
                id={field.name}
                {...field}
                autoFocus
                maxLength={500}
                rows={5}
                cols={30}
                className={classNames({
                  "p-invalid": fieldState.invalid,
                })}
              />
            )}
          />

          <label htmlFor="notes">
            Notes <small>(max 500 characters)</small>
          </label>
        </span>
        <small className="block pt-1">
          {errors.notes && (
            <div className="text-red-500">{errors.notes.message}</div>
          )}
        </small>
      </div>
      <div className="flex flex-column align-items-start gap-2">
        <label
          htmlFor="file-test"
          className="p-button p-component p-fileupload-choose w-auto"
        >
          <input
            id="file-test"
            type="file"
            multiple
            hidden
            ref={inputFilesRef}
            onChange={onLoadFiles}
          />
          <i className="pi pi-plus mr-2" /> Upload files
        </label>

        {files.map((f) => (
          <Chip
            key={f.name}
            label={decodeURIComponent(f.name)}
            removable
            onRemove={() => onRemoveFile(f)}
          />
        ))}
      </div>
    </>
  );
}
