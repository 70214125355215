import { getHasPermission } from "../helpers/helpers";
import { useLocalState } from "./useLocalState";

export const useSectionPermissions = (section, actions) => {
  const [userData] = useLocalState("", "userData");
  const [roles] = userData[0].user.roles;
  const _permissions = roles?.permissions || null;
  if (!_permissions) return {};

  const permissions = actions.reduce((acc, a) => {
    acc[a] = getHasPermission(_permissions, `${section} ${a}`);

    return acc;
  }, {});

  return permissions;
};
