import axiosInstance from "./axios";

export async function fetchHouseHolds(rows, page, property_id) {
  let url = `household/list/${rows}/created_at/desc?page=${page}&context_property_id=${property_id}`;
  return axiosInstance.get(url);
}

export function fetchHouseHoldByCriteria(data, context_property_id) {
  const params = new URLSearchParams();
  if (data) {
    params.append("search_field", data);
  }
  params.append("context_property_id", context_property_id);
  const url = `household/gethousebycriteria?${params.toString()}`;

  return axiosInstance.get(url);
}

export function fetchHouseHoldByPropertyId(context_property_id) {
  let url = `household/gethousebycriteria?context_property_id=${context_property_id}`;
  return axiosInstance.get(url);
}

export async function addHouseHold(data) {
  let url = `household/store`;
  return axiosInstance.post(url, data.json);
}

export function updateHouseHold(json) {
  let url = `household/update`;
  return axiosInstance.post(url, json);
}

export function deleteHouseHold(id) {
  let url = `household/delete`;
  return axiosInstance.delete(url, { data: { house_hold_id: id } });
}
