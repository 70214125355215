import axiosInstance from "./axios";
import * as moment from "moment";
import secureLocalStorage from "react-secure-storage";
import { DB_NAME, DB_VERSION } from "../utils/Constansts";
import { blobToArrayBuffer } from "../helpers/helpers";

//import { ACCESS_TOKEN } from "../utils/Constansts";

export async function fetchUsers(rows, page, property_id) {
  let url = `user/list/${rows}/created_at/desc?page=${page}&context_property_id=${property_id}`;
  return axiosInstance.get(url);
}


export async function fetchRoles() {
  let url = "role";
  return axiosInstance.get(url);
}

export function fetchUser(id) {
  let url = `user/show?user_id=${id}`;
  return axiosInstance.get(url);
}

export async function addUser(data) {
  let url = `user/store`;
  return axiosInstance.post(url, data.json);
}

export function updateUser(json) {
  let url = `user/update`;
  return axiosInstance.post(url, json);
}

export function deleteUser(id) {
  let url = `user/delete`;
  return axiosInstance.delete(url, {data: { user_id: id }});
}

export function updateUserStatus(id) {
  let url = `user/updateuserstatus`;
  return axiosInstance.post(url, { user_id: id });
}


