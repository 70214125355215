import { useRef, useState } from "react";
import { useForm } from "react-hook-form";
import { useLocalState } from "../../../hooks/useLocalState";
import { Sidebar } from "primereact/sidebar";
import { Button } from "primereact/button";
import { Steps } from "primereact/steps";
import InformationTab from "./information-tab";
import SectionsTab from "./sections-tab";
import {
  useInsertProperty,
  useProperty,
  useUpdateProperty,
} from "../../../hooks/propertyQueries";
import { useUpdateEffect } from "primereact/hooks";
import { toast } from "react-toastify";
import { TOAST_CONFIG } from "../../../utils/Constansts";
import { readFiles } from "../../../utils";
import Loader from "../../loader";

function Header({ propertyId, activeTabIndex, isSubmitting, onBack }) {
  return (
    <div className="w-full flex align-items-center justify-content-between">
      <div>
        <h2>{propertyId ? "Update property" : "Create property"}</h2>
      </div>
      <div className="flex gap-2">
        {!propertyId ? (
          <>
            <Button
              visible={activeTabIndex === 0}
              type="submit"
              label="Next"
              form="information"
            />

            <Button
              visible={activeTabIndex === 1}
              disabled={isSubmitting}
              type="button"
              label="Back"
              onClick={onBack}
              text
            />
          </>
        ) : null}

        <Button
          visible={activeTabIndex === 1 || Boolean(propertyId)}
          disabled={isSubmitting}
          type="submit"
          form={activeTabIndex === 0 ? "information" : "sections"}
          label={isSubmitting ? "Loading..." : propertyId ? "Update" : "Create"}
        />
      </div>
    </div>
  );
}

function validateCitations(vehicleCitationTypes, formData) {
  const error = {};

  vehicleCitationTypes
    .filter((vc) => vc.type && formData[vc.key])
    .forEach((vc) => {
      if (
        vc.type === "numeric" &&
        (!formData[`${vc.key}-value`] || formData[`${vc.key}-value`] <= 0)
      ) {
        error.key = vc.key;
        error.message = "This field is required and must be a positive number";
      } else if (vc.type === "range") {
        Object.keys(formData)
          .filter((k) => k.startsWith(`${vc.key}-from`))
          .forEach((k) => {
            const from = formData[k];
            const toKey = k.replace("-from", "-to");
            const to = formData[toKey];

            if (!from || !to) {
              error.key = vc.key;
              error.message = "You must enter valid 'from' and 'to' values";
            }

            if (from > to) {
              error.key = vc.key;
              error.message = "'from' can not be greater than 'to'";
            }
          });
      }
    });

  return error;
}

function getCitationsValues(vehicleCitationTypes, formData) {
  const citations = [];

  vehicleCitationTypes
    .filter((vc) => formData[vc.key] && vc.type !== "range")
    .forEach((vc) => {
      const citation = {
        citation_type_id: vc.citation_type_id,
        property_citation_type_id:
          formData[`${vc.key}-property_citation_type_id`] ?? null,
        period_by:
          formData.citations_apply_by === 1
            ? formData[`${vc.key}-period-by`]
            : formData.citation_period_by,
        days:
          (formData.citations_apply_by === 1
            ? formData[`${vc.key}-days`]
            : formData.citations_number_of_days) ?? null,
        count_each_row: formData[`${vc.key}-count-to-tow`],
      };
      if (vc.type === "numeric") {
        citation.value = formData[`${vc.key}-value`];
      }

      citations.push(citation);
    });

  return citations;
}

function getRangeCitationsValues(vehicleCitationTypes, formData) {
  const citations = [];

  vehicleCitationTypes
    .filter((vc) => formData[vc.key] && vc.type === "range")
    .forEach((vc) => {
      const citation = {
        citation_type_id: vc.citation_type_id,
        period_by:
          formData.citations_apply_by === 1
            ? formData[`${vc.key}-period-by`]
            : formData.citation_period_by,
        days:
          (formData.citations_apply_by === 1
            ? formData[`${vc.key}-days`]
            : formData.citations_number_of_days) ?? null,
        count_each_row: formData[`${vc.key}-count-to-tow`],
      };

      Object.keys(formData)
        .filter((k) => k.startsWith(`${vc.key}-from`))
        .forEach((k) => {
          const from = formData[k];
          const toKey = k.replace("-from", "-to");
          const to = formData[toKey];
          const idKey = k.replace("-from", "-property_citation_type_id");
          const property_citation_type_id = formData[idKey] ?? null;

          citations.push({ ...citation, from, to, property_citation_type_id });
        });
    });

  return citations;
}

function getInformationDefaultValues(property) {
  return {
    name: property?.name ?? "",
    property_type_id: property?.property_type_id ?? "",
    street: property?.street ?? "",
    number: property?.number ?? "",
    zip_code: property?.zip_code ?? "",
    gps_coordinates: property?.gps_coordinates ?? "",
    city: property?.city ?? "",
    contact_name: property?.contact_name ?? "",
    telephone: property?.telephone ?? "",
    email: property?.email ?? "",
    contact_telephones:
      property?.contact_telephones.map((t) => t.contact_telephone_id) ?? [],
    contact_is_property_manager: property?.contact_is_property_manager ?? false,
    maintenance_email: property?.maintenance_email ?? "",
    notes: property?.notes ?? "",
  };
}

const SECTIONS = [
  "safelistings",
  "households",
  "pools",
  "vehicles",
  "users",
  "schedule",
  "shifts",
  "vehiclemaintenance",
  "dar",
  "incidents",
  "citations",
  "poolaccesslogs",
];

function getSectionsDefaultValues(property) {
  const sections = SECTIONS.reduce((acc, s) => {
    const section = property?.property_hired_types.find((t) => t.key === s);
    const granted = section?.granted === 1;

    acc[s] = granted;
    return acc;
  }, {});

  const rules =
    property?.rules.reduce((acc, r) => {
      if (r.type === "boolean") {
        acc[r.key] = r.value === 1;
      } else if (r.type === "number") {
        acc[r.key] = r.value;
      }
      return acc;
    }, {}) ?? {};

  const citations = property?.property_citation_types.reduce((acc, c) => {
    acc[c.key] = true;
    acc[`${c.key}-property_citation_type_id`] = c.property_citation_type_id;

    if (c.type === "numeric") {
      acc[`${c.key}-value`] = c.value;
    } else if (c.type === "range") {
      const rangeCount = Object.keys(acc).filter((k) =>
        k.startsWith(`${c.key}-from`)
      ).length;
      acc[`${c.key}-from-${rangeCount + 1}`] = c.from;
      acc[`${c.key}-to-${rangeCount + 1}`] = c.to;
      acc[`${c.key}-property_citation_type_id-${rangeCount + 1}`] =
        c.property_citation_type_id;
    }

    return acc;
  }, {});

  return {
    ...sections,
    ...rules,
    ...citations,
  };
}

export default function SecondSheet({ isVisible, propertyId, onClose }) {
  const [files, setFiles] = useState([]);
  const formDataRef = useRef({});
  const inputFilesRef = useRef();
  const [activeTabIndex, setActiveTabIndex] = useState(0);
  const [mandatorySections, setMandatorySections] = useState([]);
  const [propertyTypes] = useLocalState("", "propertyTypes");
  const [vehicleCitationTypes] = useLocalState("", "vehicleCitationTypes");
  const [contactTelephones] = useLocalState("", "contactTelephones");

  const {
    mutateAsync: mutateInsert,
    isError: isErrorInsert,
    isSuccess: isSuccessInsert,
  } = useInsertProperty();

  const {
    mutateAsync: mutateUpdate,
    isError: isErrorUpdate,
    isSuccess: isSuccessUpdate,
  } = useUpdateProperty();

  const { isPending: _isPending, data } = useProperty(propertyId);
  const property = !_isPending ? data.data.data : null;

  const isPending = propertyId && _isPending;

  const infoDefaultValues = getInformationDefaultValues(property);
  const sectionDefaultValues = getSectionsDefaultValues(property);

  const {
    watch,
    getValues,
    control: informationControl,
    handleSubmit: handleInformationSubmit,
    formState: { errors: informationErrors },
  } = useForm({
    values: infoDefaultValues,
  });

  const {
    watch: sectionsWatch,
    control: sectionsControl,
    handleSubmit: handleSectionsSubmit,
    formState: { errors: sectionsErrors, isSubmitting },
    reset,
    setError,
    setValue,
  } = useForm({
    values: sectionDefaultValues,
  });

  const isPropertyManager = watch("contact_is_property_manager");
  const property_type_id = watch("property_type_id");

  useUpdateEffect(() => {
    if (propertyId && !isPending && !Object.keys(formDataRef.current).length) {
      formDataRef.current = {
        ...infoDefaultValues,
      };
      if (property.files.length) {
        setFiles(property.files);
      }
    }
  }, [propertyId, property, isPending, infoDefaultValues]);

  useUpdateEffect(() => {
    if (isSuccessInsert || isSuccessUpdate) {
      const action = propertyId ? "updated" : "created";
      const msg = `Property ${action} successfully.`;
      toast.success(msg, TOAST_CONFIG);

      onClose();
    }
  }, [isSuccessInsert, isSuccessUpdate]);

  useUpdateEffect(() => {
    if (isErrorInsert || isErrorUpdate) {
      const msg = `Something went wrong. Please try again or contact with an administrator.`;
      toast.error(msg, TOAST_CONFIG);
    }
  }, [isErrorInsert, isErrorUpdate]);

  useUpdateEffect(() => {
    if (property_type_id) {
      const sections = propertyTypes
        .find((pt) => pt.property_type_id === property_type_id)
        .sections.filter((s) => s.mandatory === 1)
        .map((s) => s.key);

      sections.forEach((s) => {
        setValue(s, true);
      });

      setMandatorySections(sections);
    }
  }, [property_type_id, propertyTypes]);

  const handleInformationForm = (data) => {
    formDataRef.current = {
      ...formDataRef.current,
      ...data,
      contact_telephones: data.contact_telephones.map((t) => ({
        contact_telephone_id: t,
      })),
    };
    if (propertyId) {
      onSubmit();
    } else {
      setActiveTabIndex(1);
    }
  };

  const handleBack = () => {
    reset();
    setActiveTabIndex(0);
  };

  const onSubmit = async (data = {}) => {
    const error = validateCitations(vehicleCitationTypes, data);

    if (error.key) {
      setError(error.key, error.message);
      return;
    }

    const type = propertyTypes.find(
      (p) => p.property_type_id === formDataRef.current.property_type_id
    );
    const sections = type.sections
      .filter((s) => data[s.key])
      .map((s) => ({ section_id: s.section_id, granted: 1 }));
    const rules = type.rules
      .filter((r) => typeof data[r.key] !== "undefined")
      .map((r) => ({ rule_id: r.rule_id, value: data[r.key] }));

    const citations = [];

    const citationsValues = getCitationsValues(
      vehicleCitationTypes,
      data,
      property?.property_citation_types
    );
    if (citationsValues.length) {
      citations.push(...citationsValues);
    }

    const rangeCitations = getRangeCitationsValues(
      vehicleCitationTypes,
      data,
      property?.property_citation_types
    );
    if (rangeCitations.length) {
      citations.push(...rangeCitations);
    }

    let base64Files = [];
    let _files = files;
    let filesDel = [];

    if (property?.files.length) {
      const urls = property.files.map((f) => f.url);
      _files = files.filter((f) => !urls.includes(f.url));

      filesDel = property.files
        .filter((f) => !files.find((_f) => _f.url === f.url))
        .map((f) => f.url);
    }

    if (_files.length) {
      base64Files = await readFiles(_files);
    }

    let propertyData = {
      ...formDataRef.current,
      files: base64Files,
    };

    if (filesDel.length) {
      propertyData.files_del = filesDel;
    }

    if (sections.length) {
      propertyData.sections = sections;
    }

    if (rules.length) {
      propertyData.rules = rules;
    }

    if (citations.length) {
      propertyData.citation_types = citations;
    }

    const mutate = propertyId ? mutateUpdate : mutateInsert;
    if (propertyId) {
      propertyData = { ...propertyData, property_id: propertyId };
    } else {
      propertyData = {
        json: propertyData,
        persistOnStorage: true,
      };
    }

    await mutate(propertyData);
  };

  const handleLoadFiles = (e) => {
    const files = e.target.files;

    setFiles(Array.from(files));
  };

  const handleRemoveFile = (file) => {
    setFiles((old) => {
      return old.filter((f) => f.name !== file.name);
    });

    inputFilesRef.current.value = "";
  };

  const handleNavigation = (e) => {
    if (activeTabIndex === 0) {
      const values = getValues();
      formDataRef.current = {
        ...formDataRef.current,
        ...values,
        contact_telephones: values.contact_telephones.map((t) => ({
          contact_telephone_id: t,
        })),
      };
    }
    setActiveTabIndex(e.index);
  };

  return (
    <Sidebar
      visible={isVisible}
      position="right"
      header={() => (
        <Header
          propertyId={propertyId}
          activeTabIndex={activeTabIndex}
          isSubmitting={isSubmitting}
          onBack={handleBack}
        />
      )}
      onHide={onClose}
      className="w-full md:w-7 lg:w-7"
    >
      {isPending ? (
        <Loader />
      ) : (
        <>
          <Steps
            model={[{ id: 0 }, { id: 1 }]}
            activeIndex={activeTabIndex}
            className="pt-0"
            readOnly={!propertyId}
            onSelect={handleNavigation}
          />
          {activeTabIndex === 0 ? (
            <form
              id="information"
              className="p-fluid"
              onSubmit={handleInformationSubmit(handleInformationForm)}
            >
              <InformationTab
                isPropertyManager={isPropertyManager}
                control={informationControl}
                propertyTypes={propertyTypes}
                contactTelephones={contactTelephones}
                files={files}
                errors={informationErrors}
                inputFilesRef={inputFilesRef}
                onLoadFiles={handleLoadFiles}
                onRemoveFile={handleRemoveFile}
              />
            </form>
          ) : null}
          {activeTabIndex === 1 ? (
            <form
              id="sections"
              className="p-fluid"
              onSubmit={handleSectionsSubmit(onSubmit)}
            >
              <SectionsTab
                control={sectionsControl}
                watch={sectionsWatch}
                errors={sectionsErrors}
                mandatorySections={mandatorySections}
                vehicleCitationTypes={vehicleCitationTypes}
              />
            </form>
          ) : null}
        </>
      )}
    </Sidebar>
  );
}
