const imagesRgx = /\.(jpe?g|png|bmp)$/i;

const mailRgx =
  /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/;

const validateMail = (mail) => {
  const result = mail.match(mailRgx);
  return Array.isArray(result);
};

const toCamelCase = (str) =>
  str
    .split(/[ _]/g)
    .map((t, i) => {
      if (i === 0) {
        return t.toLowerCase();
      }
      const cap = t[0].toUpperCase();
      return cap + t.substring(1).toLowerCase();
    })
    .join("");

const toSnakeCase = (string) =>
  string
    .replace(/([a-z])([A-Z])/g, "$1_$2")
    .replace(/[\s_]+/g, "_")
    .toLowerCase();

const toScreenFormat = (string) => string.replace(/_/g, " ");

const objToSnakeCase = (formData) =>
  Object.keys(formData).reduce((acc, key) => {
    const newKey = toSnakeCase(key);
    let value = formData[key];

    if (value && typeof value === "object" && !Array.isArray(value)) {
      value = objToSnakeCase(value);
    } else if (
      Array.isArray(value) &&
      value.some((e) => typeof e === "object" && !Array.isArray(e))
    ) {
      value = value.map(objToSnakeCase);
    }

    return {
      ...acc,
      [newKey]: value,
    };
  }, {});

const objToCamelCase = (obj) =>
  Object.keys(obj).reduce((acc, key) => {
    const newKey = toCamelCase(key);
    let value = obj[key];
    if (value && typeof value === "object" && !Array.isArray(value)) {
      value = objToCamelCase(value);
    } else if (
      Array.isArray(value) &&
      value.some((e) => typeof e === "object" && !Array.isArray(e))
    ) {
      value = value.map(objToCamelCase);
    }

    return {
      ...acc,
      [newKey]: value,
    };
  }, {});

const { REACT_APP_API_URL } = process.env;

const getPromise = (endpoint, options = {}, user) => {
  const config = {
    method: "GET",
    ...options,
    headers: {
      ...options?.headers,
      Authorization: user ? `Bearer ${user.accessToken}` : undefined,
      "Content-type": "application/json",
      Accept: "application/json",
    },
  };

  if (options.body) {
    config.method = "POST";
    config.body = JSON.stringify(objToSnakeCase(options.body));
  }

  const fullEndpoint = `${REACT_APP_API_URL}${endpoint}`;
  return fetch(fullEndpoint, config)
    .then((response) => {
      if (!response.ok) {
        throw response;
      }

      return response.json();
    })
    .then((data) => objToCamelCase(data));
};

const getPaginationState = (
  data = {},
  currentPage,
  setCurrentPage,
  itemsPerPage
) => ({
  currentPage,
  needsPagination: data.total > itemsPerPage,
  total: data.total,
  totalPages: data.lastPage,
  perPage: data.perPage,
  from: data.from,
  to: data.to,
  onFirstPage: currentPage === 1,
  onLastPage: currentPage === data.lastPage,
  previousPage: () => {
    if (currentPage <= 1) {
      return;
    }
    setCurrentPage((current) => current - 1);
  },
  nextPage: () => {
    if (currentPage >= data?.lastPage) {
      return;
    }
    setCurrentPage((current) => current + 1);
  },
  toPage: (page) => {
    if (page < 1 || page > data?.lastPage) {
      return;
    }
    setCurrentPage(page);
  },
});

const getDateToDB = (dateObj = new Date(), { withHours = true } = {}) => {
  const end = withHours ? 19 : 10;
  const utcDate = dateObj.toISOString().slice(0, end).replace("T", " ");

  return utcDate;
};

const getHoursToDB = (dateObj = new Date()) =>
  dateObj.toISOString().slice(11, 19);

const formatDate = (
  date,
  { withHours = true, isUTC = true } = {},
  customOptions = {}
) => {
  let time;
  if (date instanceof Date) {
    time = date.getTime();
  } else if (typeof date === "string") {
    /** safari does not suppor '-' as divider for dates */
    let stringDate = date.replace(/-/g, "/");
    if (isUTC) {
      stringDate = `${stringDate} UTC`;
    }
    time = new Date(stringDate).getTime();
  }
  const options = {
    year: "2-digit",
    month: "numeric",
    day: "numeric",
    ...customOptions,
  };
  if (withHours) {
    options.hour = "numeric";
    options.minute = "numeric";
  }
  return new Intl.DateTimeFormat("en-US", options).format(time);
};

const formatTime = (date) => {
  /** safari does not suppor '-' as divider for dates */
  const stringDate = date.replace(/-/g, "/");
  const time = new Date(stringDate).getTime();

  const options = {
    hour: "numeric",
    minute: "numeric",
  };

  return new Intl.DateTimeFormat("en-US", options).format(time);
};

const getResponseError = (response, callback) => {
  if (response.message === "Failed to fetch") {
    callback(response.message);
    return;
  }
  if (!response.body?.locked) {
    response.json().then((error) => {
      const message = Array.isArray(error)
        ? error
            .map((e) => {
              const keys = Object.keys(e);
              return keys.map((key) => e[key]).join(" - ");
            })
            .join("\n")
        : error?.message;
      callback(message);
    });
  }
};

const readFiles = (files) => {
  if (files.length === 0) {
    return Promise.resolve([]);
  }

  return new Promise((resolve) => {
    const base64Files = [];
    let currentFile = 0;
    const readFileData = (file) => {
      const reader = new FileReader();
      reader.onload = () => {
        base64Files.push({
          base64: reader.result,
          name: file.name,
          type: file.type,
        });
        currentFile += 1;

        if (currentFile === files.length) {
          resolve(base64Files);
        }
      };

      reader.readAsDataURL(file);
    };

    for (const file of files) {
      readFileData(file);
    }
  });
};

const formatQueryParams = (params) =>
  params
    ? Object.keys(params).reduce(
        (acc, key) =>
          acc
            ? `${acc}&${toSnakeCase(key)}=${params[key]}`
            : `${toSnakeCase(key)}=${params[key]}`,
        ""
      )
    : "";

const basisFact = (basis) => ({
  "--mobile-display": basis?.mobile?.display,
  "--tablet-display":
    basis?.tablet?.display || basis?.mobile?.display || basis?.laptop?.display,
  "--laptop-display":
    basis?.laptop?.display || basis?.tablet?.display || basis?.mobile?.display,
  "--mobile-basis": basis?.mobile?.basis,
  "--tablet-basis":
    basis?.tablet?.basis || basis?.mobile?.basis || basis?.laptop?.basis,
  "--laptop-basis":
    basis?.laptop?.basis || basis?.tablet?.basis || basis?.mobile?.basis,
});

const ITEMS_PER_PAGE = 10;

export {
  imagesRgx,
  validateMail,
  toCamelCase,
  toSnakeCase,
  toScreenFormat,
  objToSnakeCase,
  objToCamelCase,
  getPaginationState,
  getDateToDB,
  getHoursToDB,
  getResponseError,
  getPromise,
  formatDate,
  formatTime,
  readFiles,
  basisFact,
  formatQueryParams,
  ITEMS_PER_PAGE,
};
