import React, { useContext } from "react";
import { useQueryClient } from "@tanstack/react-query";
import { Avatar } from "primereact/avatar";
import { Button } from "primereact/button";
import VisibleContext from "../../context/VisibleContext";
import { useLocalState } from "../../hooks/useLocalState";

import Datatable from "./Datatable";
import SideBar from "./SideBar";
import { Card } from "primereact/card";

const Vehicles = () => {
  const queryClient = useQueryClient();

  const { VisibleFormContext } = useContext(VisibleContext);
  const [visibleForm, setVisibleForm] = VisibleFormContext;

  const [userData] = useLocalState("", "userData");

  const [roles] = userData[0].user.roles;
  const permissions = roles?.permissions || null;

  let permissionsObject = {
    create: permissions.find(({ name }) => name === "vehicle create")
      ? true
      : false,
    show: permissions.find(({ name }) => name === "vehicle show")
      ? true
      : false,
    update: permissions.find(({ name }) => name === "vehicle update")
      ? true
      : false,
    delete: permissions.find(({ name }) => name === "vehicle delete")
      ? true
      : false,
  };

  const refetchVehicles = () => {
    queryClient.invalidateQueries({
      queryKey: ["vehicles"],
      refetchActive: true,
      refetchInactive: false,
    });
  };

  const openSidebarForm = () => {
    setVisibleForm({
      visible: true,
      itemId: null,
      title: "New Vehicle",
    });
  };

  return (
    <>
      {permissionsObject.create && (
        <SideBar permissionsObject={permissionsObject} />
      )}
      <Card>
        <div className="flex flex-wrap justify-content-between gap-2 mb-1">
          <div className="p-toolbar-group-start p-toolbar-group-left">
            <Avatar
              label="Vehicles"
              size="large"
              style={{ backgroundColor: "white", width: "8rem" }}
            />
            <Button
              icon="pi pi-refresh"
              rounded
              text
              aria-label="Refresh"
              tooltip="Refresh"
              onClick={refetchVehicles}
            />
          </div>
          {permissionsObject.create && (
            <Button onClick={openSidebarForm} label="New Vehicle" id="newBtn" />
          )}
        </div>

        <Datatable permissionsObject={permissionsObject} />
      </Card>
    </>
  );
};

export default Vehicles;
