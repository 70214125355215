import { useNavigate } from "react-router-dom";
import { useUnmountEffect } from "primereact/hooks";
import { useContext } from "react";
import VisibleContext from "../../context/VisibleContext";
import { usePosition } from "../../context/position";

import { useLocalState } from "../../hooks/useLocalState";

import Datatable from "./Datatable";
import SideBar from "./SideBar";

import PositionError from "../PositionError/PositionError";

import { Avatar } from "primereact/avatar";
import { Button } from "primereact/button";

import { useQueryClient } from "@tanstack/react-query";
import { Card } from "primereact/card";

const PoolAccessLogs = () => {
  const navigate = useNavigate();
  const queryClient = useQueryClient();

  const { VisibleFormContext, visibleLoadingModalContext } =
    useContext(VisibleContext);
  const [visibleForm, setVisibleForm] = VisibleFormContext;
  const [visibleLoadingModal, setVisibleLoadingModal] =
    visibleLoadingModalContext;

  const [userData] = useLocalState("", "userData");
  const [roles] = userData[0].user.roles;
  const permissions = roles?.permissions || null;

  let permissionsObject = {};
  permissionsObject["create"] = permissions.find(
    ({ name }) => name === "poolaccesslog create"
  )
    ? true
    : false;
  permissionsObject["show"] = permissions.find(
    ({ name }) => name === "poolaccesslog show"
  )
    ? true
    : false;
  permissionsObject["update"] = permissions.find(
    ({ name }) => name === "poolaccesslog update"
  )
    ? true
    : false;
  permissionsObject["delete"] = permissions.find(
    ({ name }) => name === "poolaccesslog delete"
  )
    ? true
    : false;

  //const [visibleForm,setVisibleForm] = useLocalState(null,"visibleForm");

  const refetchpoolAccessLogS = (e) => {
    queryClient.invalidateQueries({
      queryKey: ["poolLogs"],
      //exact: true,
      refetchActive: true,
      refetchInactive: false,
    });
  };

  const openSidebarForm = () => {
    /*positionContext?.getPosition((position) => {
      if (position.error) {
        return <PositionError />;
      }
    });*/

    setVisibleForm({
      visible: true,
      itemId: null,
      title: "New Pool Access Log",
    });
    //console.log(visibleForm);
    //Fconsole.log(visibleFormVariables.itemId);
  };

  return (
    <>
      {permissionsObject.create ? (
        <SideBar permissionsObject={permissionsObject} />
      ) : null}
      <Card>
        <div className="flex flex-wrap justify-content-between gap-2 mb-1">
          <div className=" p-toolbar-group-start p-toolbar-group-left">
            <Avatar
              label="Pool Access Logs"
              size="large"
              style={{ backgroundColor: "white", width: "12rem" }}
            />
            <Button
              icon="pi pi-refresh"
              rounded
              text
              aria-label="Refresh"
              tooltip="Refresh"
              onClick={(e) => refetchpoolAccessLogS(e)}
            />
          </div>
          {permissionsObject.create ? (
            <Button
              onClick={() => openSidebarForm()}
              label="New Pool Access Log"
              id="newBtn"
              //style={{ backgroundColor: "#3F51B580" }}
            />
          ) : null}
        </div>
        <Datatable permissionsObject={permissionsObject} />
      </Card>
    </>
  );
};

export default PoolAccessLogs;
