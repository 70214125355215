import React, { useRef } from "react";
import { useReactToPrint } from "react-to-print";

import { Dialog } from "primereact/dialog";
import { Galleria } from "primereact/galleria";
import { ScrollTop } from "primereact/scrolltop";
import { ProgressSpinner } from "primereact/progressspinner";
import { Button } from "primereact/button";
import { Tag } from "primereact/tag";
import { OverlayPanel } from "primereact/overlaypanel";

import Map from "../map";
import { useUser } from "../../hooks/userQueries";

import * as moment from "moment";
import { utcToLocale } from "../../helpers/helpers";

const responsiveOptions = [
  {
    breakpoint: "1024px",
    numVisible: 5,
  },
  {
    breakpoint: "768px",
    numVisible: 3,
  },
  {
    breakpoint: "560px",
    numVisible: 1,
  },
];

const statusBodyTemplate = (status) => {
    if (!status) return null;

    if (status === "Disabled") {
      return (
        <Tag
          severity="danger"
          className="w-full text-base"
          text="white"
          style={{
            padding: "0.4rem",
            backgroundColor: "#d32f2fd1",
            textAlign: "center",
          }}
        >
          {status}
        </Tag>
      );
    } else
      return (
        <Tag
          severity="success"
          className="w-full text-base"
          text="white"
          style={{
            padding: "0.4rem",
            backgroundColor: "#689f38d1",
            textAlign: "center",
          }}
        >
          {status}
        </Tag>
      );
  };

export const UserInfoModal = ({ isVisible, userId, onClose }) => {
  const { isPending: isPendingUser, data: fetchedUser } = useUser(userId);
  const printRef = useRef();


  return (
    <Dialog
      className="w-11 sm:w-8 md:w-7 lg:w-7 xl:w-6 h-auto"
      showHeader={false}
      contentStyle={{ borderTopLeftRadius: "4px", borderTopRightRadius: "4px" }}
      dismissableMask
      closeOnEscape
      visible={isVisible}
      onHide={onClose}
    >
      {isPendingUser ? (
        <div className="flex flex-wrap h-18rem justify-content-between gap-2 mb-1">
          <div
            className="h-6rem"
            style={{
              position: "absolute",
              top: "50%",
              left: "50%",
              transform: "translate(-50%, -50%)",
            }}
          >
            <ProgressSpinner
              aria-label="Loading"
              style={{ width: "50px", height: "100vm" }}
              strokeWidth="2"
              fill="var(--surface-ground)"
              animationDuration=".5s"
            />

            <p>Loading</p>
          </div>
        </div>
      ) : (
        <>
          <div ref={printRef} className="p-2 ">
            <div className="grid">
              <div className="col">
                <span className="align-items-center inline-flex ">
                  <h2>
                    USER # {fetchedUser?.data.data.user_id} || {fetchedUser?.data.data.role_name}
                  </h2>
                </span>
              </div>

              <div className="col">
                <div className="flex align-items-end justify-content-end">
                  <span className="mt-3">
                    <Button
                      icon="pi pi-times"
                      rounded
                      link
                      text
                      onClick={onClose}
                    />
                  </span>
                </div>
              </div>
            </div>

            <div className="">
            <b>{statusBodyTemplate(fetchedUser?.data.data.status)}</b>
              <p className="text-base">
                Name: <b>{fetchedUser?.data.data.first_name + " "+ fetchedUser?.data.data.last_name}</b>
              </p>
              <p className="text-base">
                Username: <b>{fetchedUser?.data.data.user_name}</b>
              </p>
              <p className="text-base">
                Email: <b>{fetchedUser?.data.data.email}</b>
              </p>
              <p>
                Created Date:{" "}
                <b>{moment(utcToLocale(fetchedUser?.data.data.created_at)).format("yyyy-MM-DD HH:mm")}</b> by <b>{fetchedUser?.data.data.created_by_user_name}</b>
              </p>

              <p>
                Verified At:{" "}
                <b>
                  {fetchedUser?.data.data.email_verified_at ? (
                    moment(utcToLocale(fetchedUser?.data.data.email_verified_at)).format(
                      "yyyy-MM-DD HH:mm"
                    )
                  ): "N/A"}
                </b>
              </p>
              <p>
                Last Login:{" "}
                <b>
                  {fetchedUser?.data.data.last_login_date ? (
                    moment(utcToLocale(fetchedUser?.data.data.last_login_date)).format(
                      "yyyy-MM-DD HH:mm"
                    )  + ' || '+ fetchedUser?.data.data.last_login_date_humans
                  ): "N/A"}
                </b>
              </p>
              {fetchedUser?.data.data.properties.length > 0 ? (
              <p>
                Allowed Properties: 
                  <ul>
                    {
                      fetchedUser?.data.data.properties.map(property => 
                        <li>{property.name}</li>
                      )
                    }

                  </ul>
              </p>
              ) : null}

              <ScrollTop
                target="parent"
                threshold={100}
                className="w-2rem h-2rem border-round bg-primary"
                icon="pi pi-arrow-up text-base"
              />
            </div>
          </div>
        </>
      )}
    </Dialog>
  );
};
